import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 21px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
`;
const Text = styled.div`
  font-weight: 100;
`;

export default function FeatureBox({ icon, text }) {
  return (
    <Container>
      <Icon>{icon}</Icon>
      <Text>{text}</Text>
    </Container>
  );
}
