import { useContext, useEffect, useState } from "react";

import Context from "../Context";
import { styled } from "styled-components";
import { GrAdd, GrSelect } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { nanoid } from "nanoid";
import {
  AiOutlineCheck,
  AiOutlineDown,
  AiOutlineEdit,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { serverLine } from "../controllers/serverLine";
import onChangeStop from "../controllers/onChangeStop";
import CustomSelect from "./CustomSelect";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import habitTypeOptions from "../data/habitTypeOptions";
import getDateWithSlash from "../controllers/getDateWithSlash";
import saveUserState from "../controllers/storage/saveUserState";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const HabitCreator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
`;
const Input = styled.input`
  padding: 0 15px;
  height: 40px;
  text-transform: capitalize;
  background: transparent;
  width: 100%;
  color: var(--color);
  border-radius: 10px;
  background: var(--translucent);
  border: none;
`;
const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExistingHabits = styled.div``;

const CollapseIcon = styled.div``;

export default function HabitAdder() {
  const { loggedInUser, setForm } = useContext(Context);

  let [newHabit, setNewHabit] = useState({
    type: "EVERYDAY",
    createdAt: getDateWithSlash(new Date()),
  });

  return (
    <Container>
      <HabitCreator>
        <Input
          placeholder="type new habit here"
          value={newHabit.name}
          onChange={({ target }) => {
            let editedNewHabit = { ...newHabit };
            editedNewHabit.name = target.value;
            setNewHabit(editedNewHabit);
          }}
        />
        <CustomSelect
          defaultValue={getOptionByValue(newHabit.type)}
          options={habitTypeOptions}
          onChange={(newVal) => {
            let editedNewHabit = { ...newHabit };
            editedNewHabit.type = newVal.value;
            setNewHabit(editedNewHabit);
          }}
        />
        <AdditionalSettings habitSettings={newHabit} onChange={setNewHabit} />
        <Button style={{ width: "150px" }} onClick={createHabit}>
          <ButtonIcon>
            <AiOutlineCheck />
          </ButtonIcon>
          <ButtonText>Create</ButtonText>
        </Button>
      </HabitCreator>

      <ExistingHabits>{renderHabits()}</ExistingHabits>
    </Container>
  );

  function createHabit() {
    let habits = {};

    if (loggedInUser.habits) {
      habits = { ...loggedInUser.habits };
    }

    let newHabitID = nanoid();
    let newHabits = { ...habits };
    newHabits[newHabitID] = newHabit;

    setNewHabit({ type: "EVERYDAY" });

    saveUserState({ habits: newHabits });
    setForm(null);
  }

  function renderHabits() {
    let toReturn = [];

    let habits = {};

    if (loggedInUser.habits) {
      habits = { ...loggedInUser.habits };
    }

    for (let habitID in habits) {
      let habit = habits[habitID];

      toReturn.push(<HabitBox key={habitID} habitID={habitID} habit={habit} />);
    }

    return toReturn;
  }
}

function getOptionByValue(theVal) {
  for (let item of habitTypeOptions) {
    if (item.value === theVal) return item;
  }
}

const CollapsedHabitButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0;

  cursor: pointer;
`;

const CollapsedHabit = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 0 0;
  width: 100%;
  border-bottom: 1px solid var(--translucentHard);
`;

const HabitName = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const HabitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  /* padding-bottom: 25px; */
  max-height: 500px;

  padding: 0;
  padding-bottom: 15px;
  /* align-items: center; */
  /* transition: all 0.5s ease-in; */

  ${({ editEnabled }) => {
    if (!editEnabled)
      return `
      padding:0;
      overflow: hidden;
      max-height:0;

    `;
  }}
`;

function HabitBox({ habitID, habit }) {
  const [editEnabled, setEditEnabled] = useState(false);
  const { loggedInUser } = useContext(Context);

  let habits = {};

  if (loggedInUser.habits) {
    habits = { ...loggedInUser.habits };
  }

  return (
    <CollapsedHabit>
      <CollapsedHabitButton
        onClick={() => {
          setEditEnabled(!editEnabled);
        }}
      >
        <HabitName>{habit.name}</HabitName>
        <CollapseIcon>
          {editEnabled ? <AiOutlineDown /> : <AiOutlineRight />}
        </CollapseIcon>
      </CollapsedHabitButton>

      <HabitContainer editEnabled={editEnabled}>
        <Input
          placeholder="Edit Habit Name"
          onChange={updateHabitName}
          value={habit.name}
        />
        <CustomSelect
          defaultValue={getOptionByValue(habit.type)}
          options={habitTypeOptions}
          onChange={updateHabitType}
        />
        <AdditionalSettings
          habitSettings={habit}
          onChange={changeAdditionalSettings}
        />
      </HabitContainer>
    </CollapsedHabit>
  );

  function changeAdditionalSettings(updatedHabit) {
    let newHabits = { ...habits };
    newHabits[habitID] = updatedHabit;

    saveUserState({ habits: newHabits });
  }

  function updateHabitType(newValue) {
    let newHabits = { ...habits };
    newHabits[habitID].type = newValue.value;

    saveUserState({ habits: newHabits });
  }

  function updateHabitName({ target }) {
    let newHabits = { ...habits };
    newHabits[habitID].name = target.value;

    saveUserState({ habits: newHabits }, { onlyLocally: true });

    onChangeStop({
      callback: () => {
        saveUserState({ habits: newHabits });
      },
      name: habitID,
    });
  }
}

const NumberInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--bgColor);
  padding: 0 0;
  overflow: hidden;
  justify-content: space-between;
  gap: 2px;
  align-items: center;
  border-radius: 10px;
  heigth: 40px;
  width: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const NumberInputButton = styled.div`
  display: flex;
  /* flex: 1; */
  height: 40px;
  text-align: center;
  width: 40px;
  background: var(--translucent);
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--translucentHard);
  }
`;

const NumberInputBox = styled.div`
  height: 40px;
  display: flex;
  flex: 1;
  background: var(--translucent);
  justify-content: center;
  align-items: center;
`;

const DaysOfWeek = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`;
const DaysOfMonth = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`;
const WeekContainer = styled.div`
  display: flex;
  /* padding: 5px; */
  border-radius: 5px;
  background: var(--translucent);
  cursor: pointer;
  height: 40px;
  width: 60px;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${({ highlight }) => {
    if (highlight)
      return `
    background:var(--color);
    color:var(--bgColor);
  `;

    return `
  

  &:hover {
    background: var(--translucentHard);
  }
  `;
  }}
`;

const DayOfMonthContainer = styled.div`
  display: flex;
  /* padding: 10px; */
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--translucent);
  cursor: pointer;

  ${({ highlight }) => {
    if (highlight)
      return `
    background:var(--color);
    color:var(--bgColor);
  `;

    return `
  

  &:hover {
    background: var(--translucentHard);
  }
  `;
  }}
`;

function NumberInput({
  suffix = "",
  prefix = "",
  inCaseOfOne,
  value,
  onChange,
}) {
  useEffect(() => {
    if (isNaN(value)) {
      onChange(1);
    } else if (!value) {
      onChange(1);
    }
  }, [value]);

  if (suffix) suffix = " " + suffix;
  if (prefix) prefix = prefix + " ";

  let theValue = `${prefix}${value}${suffix}`;

  if (inCaseOfOne) {
    if (value === 1) {
      theValue = inCaseOfOne;
    }
  }

  return (
    <NumberInputContainer unselectable="on">
      <NumberInputButton
        onClick={() => {
          if (isNaN(value)) {
            onChange(1);
          } else {
            onChange(value - 1);
          }
        }}
      >
        <AiOutlineLeft />
      </NumberInputButton>
      <NumberInputBox>{theValue}</NumberInputBox>
      <NumberInputButton
        onClick={() => {
          if (isNaN(value)) {
            onChange(1);
          } else {
            onChange(value + 1);
          }
        }}
      >
        <AiOutlineRight />
      </NumberInputButton>
    </NumberInputContainer>
  );
}

function AdditionalSettings({ habitSettings, onChange }) {
  console.log(habitSettings);
  let habitType = habitSettings.type;

  if (habitType == "EVERYDAY") return null;
  if (habitType == "X_TIMES_A_WEEK") {
    return (
      <NumberInput
        inCaseOfOne={"Once A Week"}
        suffix="Times A Week"
        value={habitSettings[habitType]}
        onChange={makeUpdate}
      />
    );
  } else if (habitType == "X_TIMES_A_MONTH") {
    return (
      <NumberInput
        inCaseOfOne={"Once A Month"}
        suffix="Times A Month"
        value={habitSettings[habitType]}
        onChange={makeUpdate}
      />
    );
  } else if (habitType == "SPECIFIC_DAYS_OF_WEEK") {
    return (
      <DaysOfWeek>
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Sun"}
        />
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Mon"}
        />
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Tue"}
        />
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Wed"}
        />
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Thu"}
        />
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Fri"}
        />
        <Week
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={"Sat"}
        />
      </DaysOfWeek>
    );
  } else if (habitType == "SPECIFIC_DAYS_OF_MONTH") {
    let allDateOfMonths = [];

    for (let i = 1; i <= 31; i++) {
      allDateOfMonths.push(
        <DayOfMonth
          onChange={makeUpdate}
          currentValue={habitSettings[habitType]}
          value={i}
          name={i}
        />
      );
    }
    return <DaysOfMonth>{allDateOfMonths}</DaysOfMonth>;
  } else if (habitType == "REPEAT_AFTER") {
    return (
      <NumberInput
        inCaseOfOne={"Repeat After One Day"}
        prefix="Repeat After"
        suffix="Days"
        value={habitSettings[habitType]}
        onChange={makeUpdate}
      />
    );
  }

  function makeUpdate(newValue) {
    let newSetttings = { ...habitSettings };
    newSetttings[habitType] = newValue;
    onChange(newSetttings);
  }

  function update(e) {
    makeUpdate(e.target.value);
  }
}

function Week({ value, currentValue, onChange }) {
  if (!currentValue) currentValue = [];

  let theValue = value.toUpperCase();

  return (
    <WeekContainer
      highlight={currentValue.includes(theValue)}
      onClick={() => {
        let newValues = [...currentValue];

        if (currentValue.includes(theValue)) {
          newValues.splice(currentValue.indexOf(theValue), 1);
        } else {
          newValues.push(theValue);
        }

        onChange(newValues);
      }}
    >
      {value}
    </WeekContainer>
  );
}

function DayOfMonth({ name, value, currentValue, onChange }) {
  if (!currentValue) currentValue = [];
  return (
    <DayOfMonthContainer
      highlight={currentValue.includes(value)}
      onClick={() => {
        let newValues = [...currentValue];

        if (currentValue.includes(value)) {
          newValues.splice(currentValue.indexOf(value), 1);
        } else {
          newValues.push(value);
        }

        onChange(newValues);
      }}
    >
      {name}
    </DayOfMonthContainer>
  );
}
