import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { serverLine } from "./serverLine";
import getLocalDocuments from "./storage/document/getLocalDocuments";

export default async function loadContent({ type }) {
  let documents = await serverLine.get(`/content/?type=${type}`);

  let theMap = {};

  for (let item of documents) {
    theMap[item.docID] = item;
  }

  return theMap;
}
