const habitTypeOptions = [
  { value: "EVERYDAY", label: "Everyday" },
  { value: "X_TIMES_A_WEEK", label: "X Times A Week" },
  { value: "X_TIMES_A_MONTH", label: "X Times A Month" },
  { value: "SPECIFIC_DAYS_OF_WEEK", label: "Specific Day Of Week" },
  { value: "SPECIFIC_DAYS_OF_MONTH", label: "Specific Day Of Month" },
  { value: "REPEAT_AFTER", label: "Repeat After" },
];

export default habitTypeOptions;
