import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../../Context";
import CryptoJS from "crypto-js";
import { useState } from "react";

import { nanoid } from "nanoid";
import WithHeader from "../../WithHeader";
import TabButton from "../TabButton";
import MaterialInput from "../../MaterialInput";
import CustomButton from "../../CustomButton";
import getIntermediateEncryptionKey from "../../../controllers/storage/encryptedDocs/getIntermediateEncryptionKey";
import saveUserState from "../../../controllers/storage/saveUserState";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 30px;
`;

const SubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-weight: 100;
`;
export default function ChangeEncryptionKey() {
  const { loggedInUser } = useContext(Context);
  const [oldEncryptionKey, oldNewEncryptionKey] = useState();
  const [newEncryptionKey, setNewEncryptionKey] = useState();

  if (!loggedInUser.encryptionKeyHashed)
    return (
      <Container>
        <Titles>
          <SubTitle>
            We can't let you change the key because it has not yet been created
          </SubTitle>
        </Titles>
      </Container>
    );

  return (
    <Container>
      <Titles>
        <Title>Change Encryption Key</Title>
      </Titles>

      <MaterialInput
        label="Current Encryption Password"
        value={oldEncryptionKey}
        onChange={updateOldKey}
      />
      <MaterialInput
        label="New Encryption Password"
        value={newEncryptionKey}
        onChange={updateNewKey}
      />
      <CustomButton onClick={createEncryptionKey}>Change</CustomButton>
    </Container>
  );

  function updateOldKey(e) {
    oldNewEncryptionKey(e.target.value);
  }

  function updateNewKey(e) {
    setNewEncryptionKey(e.target.value);
  }

  function oldKeyIsCorrect() {
    let encryptionKeyHashed = CryptoJS.SHA256(oldEncryptionKey);
    encryptionKeyHashed = encryptionKeyHashed.toString(CryptoJS.enc.Hex);

    if (encryptionKeyHashed == loggedInUser.encryptionKeyHashed) {
      return true;
    } else {
      window.doAlert("Wrong Encryption Key");
      return false;
    }
  }

  function createEncryptionKey() {
    if (!oldKeyIsCorrect()) return;

    let intermediateKey = getIntermediateEncryptionKey(oldEncryptionKey);

    let encryptedIntermediateKey = CryptoJS.AES.encrypt(
      intermediateKey,
      newEncryptionKey
    ).toString();

    let encryptionKeyHashed = CryptoJS.SHA256(newEncryptionKey);

    encryptionKeyHashed = encryptionKeyHashed.toString(CryptoJS.enc.Hex);

    saveUserState({ encryptedIntermediateKey, encryptionKeyHashed });

    window.doAlert("Encryption Key Changed");
  }
}
