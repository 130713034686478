import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import getDateWithSlash from "../../controllers/getDateWithSlash";
import { serverLine } from "../../controllers/serverLine";
import LoadingSection from "../LoadingSection";

import getMonthName from "../../controllers/getMonthName";
import HabitAdder from "../HabitAdder";
import { AiOutlineLeft } from "react-icons/ai";
import IncrementDecrement from "../IncrementDecrement";
import HabitBox from "./habits/HabitBox";
import HabitStatBox from "./habits/HabitStatBox";
import getDayName from "../../controllers/getDayName";
import getDaysDifference from "../../controllers/getDaysDifference";

const TopContainer = styled.div`
  display: flex;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  width: 100%;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const HabitList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;
const HabitStatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export default function Habits() {
  const { setForm, loggedInUser } = useContext(Context);
  const [date, setDate] = useState(new Date());
  const [habitStat, setHabitStat] = useState(null);

  useEffect(() => {
    serverLine.get("/habit-stat").then(setHabitStat);
  }, []);

  function updateState({ date, value, habitID }) {
    let newStat = { ...habitStat };

    if (newStat[date]) {
      newStat[date][habitID] = value;
    } else {
      newStat[date] = { [habitID]: value };
    }

    setHabitStat(newStat);
    serverLine.post("/habit-stat", { date, value, habitID });
  }

  if (!loggedInUser) return <LoadingSection />;
  if (!habitStat) return <LoadingSection />;

  return (
    <Container>
      <MainSection>
        <TopContainer>
          <IncrementDecrement
            value={
              getDayName(date) + " " + date.getDate() + " " + getMonthName(date)
            }
            onChange={(newVal) => {
              console.log(date.toISOString());
              let newDate = new Date(date.toISOString());
              newDate.setDate(newDate.getDate() + newVal);
              console.log(newDate);
              setDate(newDate);
            }}
          />
        </TopContainer>

        <HabitList>
          {renderHabitList()}{" "}
          <Button onClick={openHabitAdder}> Create/Edit Habit</Button>
        </HabitList>
      </MainSection>

      <HabitStatList>{renderHabitStatList()}</HabitStatList>
    </Container>
  );

  function renderHabitList() {
    let toRender = [];

    if (loggedInUser.habits) {
      for (let habitID in loggedInUser.habits) {
        let theDate = getDateWithSlash(date);

        let dateStat = habitStat[theDate];
        let initialStatus = false;
        if (dateStat) if (dateStat[habitID]) initialStatus = true;

        let habit = loggedInUser.habits[habitID];

        let shouldRen = shouldRender(habit);
        if (!shouldRen) continue;
        toRender.push(
          <HabitBox
            date={theDate}
            updateState={updateState}
            status={initialStatus}
            habitData={habit}
            habitID={habitID}
          />
        );
      }
    }

    return toRender;
  }

  function shouldRender(habitData) {
    let today = new Date(date.toISOString());

    if (habitData.type == "EVERYDAY") {
      return true;
    } else if (habitData.type == "X_TIMES_A_WEEK") {
      return true;
    } else if (habitData.type == "X_TIMES_A_MONTH") {
      return true;
    } else if (habitData.type == "SPECIFIC_DAYS_OF_WEEK") {
      let days = habitData.SPECIFIC_DAYS_OF_WEEK;
      let dayOfToday = getDayName(today);
      dayOfToday = dayOfToday.toUpperCase();
      if (days.includes(dayOfToday)) return true;

      return false;
    } else if (habitData.type == "SPECIFIC_DAYS_OF_MONTH") {
      let days = habitData.SPECIFIC_DAYS_OF_MONTH;
      let todaysDate = today.getDate();
      if (days.includes(todaysDate)) return true;
      return false;
    } else if (habitData.type == "REPEAT_AFTER") {
      let repeatAfter = habitData.REPEAT_AFTER;
      let totalDays = getDaysDifference(
        habitData.createdAt,
        today.toISOString()
      );
      let divisor = parseInt(repeatAfter);
      if (!isNaN(divisor)) {
        if (totalDays % divisor == 0) return true;
      }
      return false;
    }
  }

  function renderHabitStatList() {
    let toRender = [];

    if (loggedInUser.habits) {
      for (let habitID in loggedInUser.habits) {
        let habit = loggedInUser.habits[habitID];
        toRender.push(
          <HabitStatBox
            updateState={updateState}
            habitStat={habitStat}
            habitData={habit}
            habitID={habitID}
          />
        );
      }
    }

    return toRender;
  }

  function openHabitAdder() {
    setForm({
      title: "Create/Edit Habit",
      component: <HabitAdder />,
      settings: { height: "90vh" },
    });
  }
}
