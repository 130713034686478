import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../../Context";

import habitTypeOptions from "../../../data/habitTypeOptions";

import { MdDone } from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-radius: 10px;
  overflow: hidden;
`;

const CheckBox = styled.div`
  height: 70px;
  width: 70px;
  margin-top: 0;

  background-color: var(--translucent);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  opacity: 0.6;

  /* background: var(--translucent); */
  border: none;
  /* border: 1px solid var(--translucentHard); */
  /* border: 1px solid var(--color); */
  /* transition: 0.25s ease-in; */
  &:hover {
    background-color: var(--translucentHard);
  }

  color: var(--translucentHard);
  font-size: 27px;

  ${({ status }) => {
    if (status)
      return `
      background-color: #8BC34A !important;
      color: var(--bgColor);
      font-size: 27px;
      opacity: 1;
      `;
  }}
`;

const HabitData = styled.div`
  height: 70px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 15px;
  justify-content: center;
  align-items: flex-start;
  background: var(--translucent);
`;
const HabitName = styled.div`
  font-weight: 900;
`;
const HabitType = styled.div``;

export default function HabitBox({
  habitData,
  habitID,
  status,
  updateState,
  date,
}) {
  return (
    <Container onClick={postStat}>
      <HabitData>
        <HabitName>{habitData.name}</HabitName>
        <HabitType>{getHabitType()}</HabitType>
      </HabitData>
      <CheckBox status={status}>
        <MdDone />
      </CheckBox>
    </Container>
  );

  function postStat() {
    let newStatus = !status;

    console.log("saving data");
    updateState({
      habitID: habitID,
      value: newStatus,
      date: date,
    });
  }

  function getHabitType() {
    for (let item of habitTypeOptions) {
      if (item.value == habitData.type) return item.label;
    }
  }
}
