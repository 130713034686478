import styled from "styled-components";

const Path1 = styled.path``;

const Path2 = styled.path`
  fill: var(--color);
`;

const SVG = styled.svg`
  height: 45px;
  width: 45px;

  @media (max-width: 900px) {
    height: 30px;
    width: 30px;
  }
`;

export default function LogoSVG() {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="165"
      height="165"
      viewBox="0 0 165 165"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.8235 48.5C82.2042 48.5 83.3235 49.6193 83.3235 51V87.7367L111.036 55.1107C111.93 54.0584 113.507 53.9299 114.56 54.8237C115.612 55.7176 115.74 57.2953 114.847 58.3476L83.3235 95.4601V129.504L127.548 97.9493C128.672 97.1474 130.233 97.4084 131.035 98.5323C131.837 99.6562 131.576 101.217 130.452 102.019L83.3235 135.647V161C83.3235 162.381 82.2042 163.5 80.8235 163.5C79.4428 163.5 78.3235 162.381 78.3235 161V122.947L43.0798 80.6738C42.1957 79.6133 42.3386 78.0369 43.3991 77.1527C44.4596 76.2686 46.0361 76.4115 46.9202 77.472L78.3235 115.139V51C78.3235 49.6193 79.4428 48.5 80.8235 48.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123 5H42C21.5655 5 5 21.5655 5 42V123C5 143.435 21.5655 160 42 160H123C143.435 160 160 143.435 160 123V42C160 21.5655 143.435 5 123 5ZM42 0C18.804 0 0 18.804 0 42V123C0 146.196 18.804 165 42 165H123C146.196 165 165 146.196 165 123V42C165 18.804 146.196 0 123 0H42Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="165"
      height="196"
      viewBox="0 0 165 196"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.8235 80.5C84.2042 80.5 85.3235 81.6193 85.3235 83V119.737L113.036 87.1107C113.93 86.0584 115.507 85.9299 116.56 86.8237C117.612 87.7176 117.74 89.2953 116.847 90.3476L85.3235 127.46V193C85.3235 194.381 84.2042 195.5 82.8235 195.5C81.4428 195.5 80.3235 194.381 80.3235 193V154.947L45.0798 112.674C44.1957 111.613 44.3386 110.037 45.3991 109.153C46.4596 108.269 48.0361 108.412 48.9202 109.472L80.3235 147.139V83C80.3235 81.6193 81.4428 80.5 82.8235 80.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123 5H42C21.5655 5 5 21.5655 5 42V123C5 143.435 21.5655 160 42 160H123C143.435 160 160 143.435 160 123V42C160 21.5655 143.435 5 123 5ZM42 0C18.804 0 0 18.804 0 42V123C0 146.196 18.804 165 42 165H123C146.196 165 165 146.196 165 123V42C165 18.804 146.196 0 123 0H42Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="165"
      height="165"
      viewBox="0 0 165 165"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.8235 49C81.9281 49 82.8235 49.8954 82.8235 51V89.0977L111.417 55.4344C112.132 54.5925 113.394 54.4898 114.236 55.2048C115.078 55.9199 115.181 57.1821 114.466 58.0239L82.8235 95.2764V130.475L127.838 98.3563C128.737 97.7148 129.986 97.9236 130.628 98.8227C131.27 99.7219 131.061 100.971 130.162 101.612L82.8235 135.389V161C82.8235 162.105 81.9281 163 80.8235 163C79.719 163 78.8235 162.105 78.8235 161V122.766L43.4638 80.3536C42.7565 79.5052 42.8709 78.2441 43.7193 77.5368C44.5677 76.8294 45.8288 76.9438 46.5362 77.7922L78.8235 116.519V51C78.8235 49.8954 79.719 49 80.8235 49Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123 4H42C21.0132 4 4 21.0132 4 42V123C4 143.987 21.0132 161 42 161H123C143.987 161 161 143.987 161 123V42C161 21.0132 143.987 4 123 4ZM42 0C18.804 0 0 18.804 0 42V123C0 146.196 18.804 165 42 165H123C146.196 165 165 146.196 165 123V42C165 18.804 146.196 0 123 0H42Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="165"
      height="165"
      viewBox="0 0 165 165"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.8235 49C81.9281 49 82.8235 49.8954 82.8235 51V89.0977L111.417 55.4344C112.132 54.5925 113.394 54.4898 114.236 55.2048C115.078 55.9199 115.181 57.1821 114.466 58.0239L82.8235 95.2764V130.475L127.838 98.3563C128.737 97.7148 129.986 97.9236 130.628 98.8227C131.27 99.7219 131.061 100.971 130.162 101.612L82.8235 135.389V161C82.8235 162.105 81.9281 163 80.8235 163C79.719 163 78.8235 162.105 78.8235 161V122.766L43.4638 80.3536C42.7565 79.5052 42.8709 78.2441 43.7193 77.5368C44.5677 76.8294 45.8288 76.9438 46.5362 77.7922L78.8235 116.519V51C78.8235 49.8954 79.719 49 80.8235 49Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123 3H42C20.4609 3 3 20.4609 3 42V123C3 144.539 20.4609 162 42 162H123C144.539 162 162 144.539 162 123V42C162 20.4609 144.539 3 123 3ZM42 0C18.804 0 0 18.804 0 42V123C0 146.196 18.804 165 42 165H123C146.196 165 165 146.196 165 123V42C165 18.804 146.196 0 123 0H42Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="165"
      height="165"
      viewBox="0 0 165 165"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.3971 40.5C79.7778 40.5 80.8971 41.6193 80.8971 43V82.4558L108.163 47.6053C109.014 46.5179 110.585 46.3261 111.673 47.1769C112.76 48.0276 112.952 49.5989 112.101 50.6863L80.8971 90.5701V127.323L124.469 93.5705C125.561 92.725 127.131 92.9244 127.976 94.0159C128.822 95.1074 128.623 96.6777 127.531 97.5233L80.8971 133.648V161C80.8971 162.381 79.7778 163.5 78.3971 163.5C77.0163 163.5 75.8971 162.381 75.8971 161V120.058L41.0172 74.6372C40.1763 73.5422 40.3823 71.9727 41.4773 71.1318C42.5724 70.2908 44.1419 70.4968 44.9828 71.5919L75.8971 111.848V43C75.8971 41.6193 77.0163 40.5 78.3971 40.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123 3H42C20.4609 3 3 20.4609 3 42V123C3 144.539 20.4609 162 42 162H123C144.539 162 162 144.539 162 123V42C162 20.4609 144.539 3 123 3ZM42 0C18.804 0 0 18.804 0 42V123C0 146.196 18.804 165 42 165H123C146.196 165 165 146.196 165 123V42C165 18.804 146.196 0 123 0H42Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="140"
      viewBox="0 0 107 140"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.0364 43.5C54.8648 43.5 55.5364 44.1716 55.5364 45V67.0217L70.8199 47.5429C71.3313 46.8912 72.2742 46.7774 72.9259 47.2888C73.5777 47.8001 73.6915 48.7431 73.1801 49.3948L55.5364 71.8816V136C55.5364 136.828 54.8648 137.5 54.0364 137.5C53.2079 137.5 52.5364 136.828 52.5364 136V85.1153L32.8787 62.9938C32.3284 62.3746 32.3844 61.4265 33.0036 60.8762C33.6229 60.3259 34.571 60.3818 35.1212 61.0011L52.5364 80.5989V45C52.5364 44.1716 53.2079 43.5 54.0364 43.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.5 104C81.3904 104 104 81.3904 104 53.5C104 25.6096 81.3904 3 53.5 3C25.6096 3 3 25.6096 3 53.5C3 81.3904 25.6096 104 53.5 104ZM53.5 107C83.0472 107 107 83.0472 107 53.5C107 23.9528 83.0472 0 53.5 0C23.9528 0 0 23.9528 0 53.5C0 83.0472 23.9528 107 53.5 107Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5 138C35.5 137.172 36.1716 136.5 37 136.5H69C69.8284 136.5 70.5 137.172 70.5 138C70.5 138.828 69.8284 139.5 69 139.5H37C36.1716 139.5 35.5 138.828 35.5 138Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="140"
      viewBox="0 0 107 140"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.2546 41.5C55.083 41.5 55.7546 42.1716 55.7546 43V70.9058L74.8147 46.3291C75.3224 45.6745 76.2646 45.5554 76.9193 46.0631C77.5739 46.5707 77.693 47.513 77.1853 48.1676L55.7546 75.8011V136C55.7546 136.828 55.083 137.5 54.2546 137.5C53.4261 137.5 52.7546 136.828 52.7546 136V91.9966L28.873 64.8069C28.3263 64.1845 28.3877 63.2367 29.0101 62.69C29.6326 62.1433 30.5803 62.2047 31.127 62.8271L52.7546 87.4506V43C52.7546 42.1716 53.4261 41.5 54.2546 41.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.5 104C81.3904 104 104 81.3904 104 53.5C104 25.6096 81.3904 3 53.5 3C25.6096 3 3 25.6096 3 53.5C3 81.3904 25.6096 104 53.5 104ZM53.5 107C83.0472 107 107 83.0472 107 53.5C107 23.9528 83.0472 0 53.5 0C23.9528 0 0 23.9528 0 53.5C0 83.0472 23.9528 107 53.5 107Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5 138C35.5 137.172 36.1716 136.5 37 136.5H69C69.8284 136.5 70.5 137.172 70.5 138C70.5 138.828 69.8284 139.5 69 139.5H37C36.1716 139.5 35.5 138.828 35.5 138Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="123"
      height="148"
      viewBox="0 0 123 148"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.4727 42.5C63.3012 42.5 63.9727 43.1716 63.9727 44V77.0861L86.8204 47.9977C87.3321 47.3462 88.2751 47.2329 88.9266 47.7446C89.578 48.2563 89.6914 49.1993 89.1796 49.8508L63.9727 81.9429V146C63.9727 146.828 63.3012 147.5 62.4727 147.5C61.6443 147.5 60.9727 146.828 60.9727 146V100.707L32.8793 69.1258C32.3287 68.5068 32.3841 67.5587 33.003 67.0081C33.622 66.4574 34.5701 66.5129 35.1208 67.1318L60.9727 96.1933V44C60.9727 43.1716 61.6443 42.5 62.4727 42.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.5 120C93.8087 120 120 93.8087 120 61.5C120 29.1913 93.8087 3 61.5 3C29.1913 3 3 29.1913 3 61.5C3 93.8087 29.1913 120 61.5 120ZM61.5 123C95.4655 123 123 95.4655 123 61.5C123 27.5345 95.4655 0 61.5 0C27.5345 0 0 27.5345 0 61.5C0 95.4655 27.5345 123 61.5 123Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.5 146C43.5 145.172 44.1716 144.5 45 144.5H77C77.8284 144.5 78.5 145.172 78.5 146C78.5 146.828 77.8284 147.5 77 147.5H45C44.1716 147.5 43.5 146.828 43.5 146Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="123"
      height="148"
      viewBox="0 0 123 148"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.4727 42.5C63.3012 42.5 63.9727 43.1716 63.9727 44V77.0861L86.8204 47.9977C87.3321 47.3462 88.2751 47.2329 88.9266 47.7446C89.578 48.2563 89.6914 49.1993 89.1796 49.8508L63.9727 81.9429V146C63.9727 146.828 63.3012 147.5 62.4727 147.5C61.6443 147.5 60.9727 146.828 60.9727 146V100.707L32.8793 69.1258C32.3287 68.5068 32.3841 67.5587 33.003 67.0081C33.622 66.4574 34.5701 66.5129 35.1208 67.1318L60.9727 96.1933V44C60.9727 43.1716 61.6443 42.5 62.4727 42.5Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.5 120C93.8087 120 120 93.8087 120 61.5C120 29.1913 93.8087 3 61.5 3C29.1913 3 3 29.1913 3 61.5C3 93.8087 29.1913 120 61.5 120ZM61.5 123C95.4655 123 123 95.4655 123 61.5C123 27.5345 95.4655 0 61.5 0C27.5345 0 0 27.5345 0 61.5C0 95.4655 27.5345 123 61.5 123Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="120"
      viewBox="0 0 68 120"
      fill="none"
    >
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65 58V34C65 16.8792 51.1208 3 34 3C16.8792 3 3 16.8792 3 34V58C3 75.1208 16.8792 89 34 89C51.1208 89 65 75.1208 65 58ZM34 0C15.2223 0 0 15.2223 0 34V58C0 76.7777 15.2223 92 34 92C52.7777 92 68 76.7777 68 58V34C68 15.2223 52.7777 0 34 0Z"
        fill="white"
      />
      <Path1
        d="M19 118C19 116.895 19.8954 116 21 116H47C48.1046 116 49 116.895 49 118V118C49 119.105 48.1046 120 47 120H21C19.8954 120 19 119.105 19 118V118Z"
        fill="white"
      />
      <Path1
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.5 40C35.6046 40 36.5 40.8954 36.5 42V74.293L47.1117 64.0603C47.9068 63.2936 49.173 63.3166 49.9397 64.1117C50.7064 64.9068 50.6834 66.173 49.8883 66.9397L36.5 79.8498V116C36.5 117.105 35.6046 118 34.5 118C33.3954 118 32.5 117.105 32.5 116V61.3491L18.6108 47.9388C17.8162 47.1716 17.794 45.9054 18.5612 45.1108C19.3284 44.3162 20.5946 44.294 21.3892 45.0612L32.5 55.7889V42C32.5 40.8954 33.3954 40 34.5 40Z"
        fill="white"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="120"
      viewBox="0 0 86 120"
      fill="none"
    >
      <Path1
        d="M36.9706 118V89.9063M36.9706 2V39.4583V47.9167M36.9706 89.9063L84 53.6563M36.9706 89.9063V76.9167M36.9706 76.9167L2 31.6042M36.9706 76.9167V47.9167M36.9706 47.9167L68.3235 8.04167"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SVG>
  );

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="406"
      height="406"
      viewBox="0 0 406 406"
      fill="none"
    >
      <Path1
        d="M392 203C392 307.382 307.382 392 203 392C98.6182 392 14 307.382 14 203C14 98.6182 98.6182 14 203 14C307.382 14 392 98.6182 392 203Z"
        stroke-width="28"
        stroke="var(--color)"
      />
      <Path2 d="M203 142.1C175.933 162.4 27.0667 284.2 27.0667 284.2C27.0667 284.2 108.267 385.7 203 385.7C297.733 385.7 372.167 284.2 372.167 284.2C372.167 284.2 230.067 162.4 203 142.1Z" />
    </SVG>
  );
}
