import styled from "styled-components";

import { useContext } from "react";
import Context from "../Context";
import CustomButton from "./CustomButton";
import logout from "../controllers/logout";

const Box = styled.div`
  width: 100%;
  display: flex;
  /* padding-top: 50px; */
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 900px) {
    display: flex;
  }
`;

const Button = styled.div`
  cursor: pointer;
  flex: 1;
  padding: 15px 15px;
  background: var(--translucent);
  border-radius: 15px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }

  @media (max-width: 700px) {
    min-width: 90vw !important;
  }
`;

export default function Settings() {
  const { updateDarkMode, colorMode } = useContext(Context);

  return (
    <Box>
      {/* <Button onClick={changeColorMode}>
        {colorMode == "LIGHT" ? <MdOutlineDarkMode /> : <FiSun />}
      </Button> */}
      <CustomButton href="/change-encryption">Change Encryption</CustomButton>

      <CustomButton onClick={logout}>Logout</CustomButton>

      <CustomButton onClick={openLink("https://arnav.upon.one")}>
        About Developer
      </CustomButton>
      <CustomButton onClick={openLink("https://upon.one")}>
        About Company
      </CustomButton>
    </Box>
  );

  function changeColorMode() {
    updateDarkMode(colorMode == "LIGHT" ? "DARK" : "LIGHT");
  }

  function openLink(theLink) {
    return () => {
      window.open(theLink);
    };
  }
}
