import { useContext, useEffect, useState } from "react";
import Context from "../Context";
import { nanoid } from "nanoid";

import { styled } from "styled-components";

import { AiOutlineDown } from "react-icons/ai";
import { BsPause, BsPlay, BsStop } from "react-icons/bs";
import DurationSelector from "./DurationSelector";
import speak from "../controllers/speak";
// import * as workerInterval from "worker-interval";
import { clearInterval, setInterval } from "worker-timers";
import savePomodoro from "../controllers/storage/pomodoro/savePomodoro";
import { Tooltip } from "@mui/material";
import { RiRestartLine } from "react-icons/ri";

window.speakSome = speak;

const Container = styled.div`
  border: 1px solid var(--translucentHard);
  padding: 0;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
const Icon = styled.div`
  border-right: 1px solid var(--translucentHard);
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;

  gap: 15px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
`;

const OptionSelector = styled.div`
  height: 40px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: var(--translucentHard);
  }

  cursor: pointer;

  @media (max-width: 900px) {
    flex: 1;
  }
`;

const OptionName = styled.div``;
const OptionIcon = styled.div`
  font-size: 15px;
  margin-bottom: -5px;
`;

const TimerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;

  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  height: 40px;
  font-size: 21px;
  width: 40px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
  height: 40px;
  align-items: center;
  justify-content: center;

  border-left: 1px solid var(--translucentHard);

  &:hover {
    background: var(--translucentHard);
  }
`;

const TimerState = styled.div`
  display: flex;
  flex-direction: row;
  border-left: 1px solid var(--translucentHard);
  gap: 15px;
  height: 40px;
  padding: 0 15px;

  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  justify-content: center;
`;
const Mins = styled.div`
  height: 40px;
  width: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  /* font-weight: 900; */
  justify-content: center;
`;
const Secs = styled.div`
  height: 40px;
  width: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const Colon = styled.div`
  height: 40px;
  width: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
`;

const Audio = styled.audio`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

export default function PomodoroTimer() {
  const { setForm } = useContext(Context);
  const [secondsPassed, setSecondsPassed] = useState(0);
  const [projectID, setProjectID] = useState("WORK");
  const [timerStatus, setTimerStatus] = useState(false);
  const [sessionID, setSessionID] = useState(nanoid());
  const [duration, setDuration] = useState(60);
  const [sessionCompleted, setSessionCompleted] = useState(false);

  useEffect(() => {
    setSessionID(nanoid());

    let data = localStorage.getItem("holda-duration-data");
    if (data) setDuration(data);
  }, [projectID]);

  window.secondsPassed = secondsPassed;
  window.timerStatus = timerStatus;
  window.sessionID = sessionID;
  window.projectID = projectID;
  window.duration = duration;
  window.sessionCompleted = sessionCompleted;
  window.setSessionCompleted = setSessionCompleted;

  useEffect(() => {
    if (window.timerInterval) clearInterval(window.timerInterval);

    window.timerInterval = setInterval(() => {
      if (window.timerStatus) {
        let totalSeconds = window.duration * 60;

        let timeLeft = totalSeconds - window.secondsPassed;

        let minsLeft = timeLeft / 60;

        if (timeLeft <= 0) {
          setSessionCompleted(true);
          if (!window.sessionCompleted) {
            speak(`Break Time! Session Completed`);
          }
        }

        if (timeLeft > 0) {
          if (minsLeft == 2) {
            speak(`Two Minutes Left`);
          }

          if (minsLeft % 5 === 0) {
            speak(`${Math.floor(minsLeft)} Minutes Left`);
          }
          setSecondsPassed(window.secondsPassed + 1);

          let secondsInterval = 60;
          if (window.secondsPassed % secondsInterval == 0) {
            savePomodoro({
              sessionID: window.sessionID,
              projectID: window.projectID,
            });
          }
        }
      }
    }, 1000);
  }, []);

  let time = getTime();

  let showTime = (
    <TimerState>
      <Mins>{time.mins}</Mins>
      <Colon>:</Colon>
      <Secs>{time.seconds}</Secs>
    </TimerState>
  );

  let playBtnStyle = null;

  if (timerStatus) playBtnStyle = { borderLeft: "none" };

  let buttons = (
    <>
      <Tooltip title={timerStatus ? "Pause Timer" : "Start Timer"}>
        <Button style={playBtnStyle} onClick={playPauseTimer}>
          {timerStatus ? <BsPause /> : <BsPlay />}
        </Button>
      </Tooltip>

      {secondsPassed > 0 ? (
        <Tooltip title={"Stop Timer"}>
          <Button onClick={stopTimer}>
            <BsStop />
          </Button>
        </Tooltip>
      ) : null}
    </>
  );

  if (!secondsPassed) showTime = null;

  if (sessionCompleted) {
    buttons = (
      <Tooltip title={"Reset Timer"}>
        <Button onClick={stopTimer}>
          <RiRestartLine />
        </Button>
      </Tooltip>
    );

    showTime = (
      <TimerState>
        Completed!
        <Audio autoPlay>
          <source src={"/cardinal.mp3"} type="audio/mpeg" />
        </Audio>
      </TimerState>
    );
  }

  return (
    <Container>
      {secondsPassed === 0 ? (
        <OptionSelector onClick={openDurationSelector}>
          <OptionName>{duration} Mins</OptionName>
          <OptionIcon>
            <AiOutlineDown />
          </OptionIcon>
        </OptionSelector>
      ) : null}

      <TimerButtons>{buttons}</TimerButtons>

      {showTime}
    </Container>
  );

  function openDurationSelector() {
    setForm({
      title: "Select Duration",
      component: <DurationSelector callback={setDuration} />,
    });
  }

  function playPauseTimer() {
    if (!timerStatus) {
      setSessionCompleted(false);
    }

    setTimerStatus(!timerStatus);
  }

  function stopTimer() {
    setSessionCompleted(false);
    setTimerStatus(false);
    setSecondsPassed(0);
  }

  function getTime() {
    let totalSeconds = duration * 60;

    let timeLeft = totalSeconds - secondsPassed;

    let mins = timeLeft / 60;
    let seconds = timeLeft % 60;

    mins = Math.floor(mins);
    seconds = Math.round(seconds);

    mins = mins.toString();
    seconds = seconds.toString();

    if (mins.length == 1) mins = "0" + mins;
    if (seconds.length == 1) seconds = "0" + seconds;
    return { mins, seconds };
  }
}
