import { useContext, useEffect, useRef, useState } from "react";
import Context from "../Context";
import { BiDownArrow, BiPause, BiPlay, BiStop, BiTimer } from "react-icons/bi";
import { serverLine } from "../controllers/serverLine";
import { nanoid } from "nanoid";

import LoadingSection from "./LoadingSection";
import { styled } from "styled-components";

import getDateWithSlash from "../controllers/getDateWithSlash";
import { MdOutlineTimer } from "react-icons/md";
import { AiOutlineDown } from "react-icons/ai";
import { BsHeadphones, BsPause, BsPlay, BsStop } from "react-icons/bs";
import musicData from "../data/musicData";
import MusicSelector from "./MusicSelector";
import { TbHeadphonesOff } from "react-icons/tb";
import CustomButton from "./CustomButton";

const Container = styled.div`
  /* border: 1px solid var(--translucentHard); */
  padding: 0;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
const Icon = styled.div`
  border-right: 1px solid var(--translucentHard);
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  color: var(--translucentHardHard);
  gap: 15px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
`;

const SelectProject = styled.div`
  height: 40px;
  padding: 0 15px;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--translucentHard);
  }

  cursor: pointer;
`;

const ProjectName = styled.div``;
const ProjectIcon = styled.div`
  font-size: 15px;
  margin-bottom: -5px;
`;

const TimerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;

  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  height: 40px;
  font-size: 21px;
  width: 40px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
  height: 40px;
  align-items: center;
  justify-content: center;

  /* border-left: 1px solid var(--translucentHard); */

  &:hover {
    background: var(--translucentHard);
  }
`;

const TimerState = styled.div`
  display: flex;
  flex-direction: row;
  border-left: 1px solid var(--translucentHard);
  gap: 15px;
  height: 40px;
  padding: 0 15px;

  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: center;
`;
const Mins = styled.div`
  height: 40px;
  width: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  font-weight: 900;
  justify-content: center;
`;
const Secs = styled.div`
  height: 40px;
  width: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const Colon = styled.div`
  height: 40px;
  width: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
`;

const Audio = styled.audio`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

let defaultMusicID = null;
let musicIDs = Object.keys(musicData);
defaultMusicID = musicIDs[0];

export default function AmbientMusicPlayer() {
  const { setForm } = useContext(Context);
  const musicRef = useRef(null);
  const [status, setStatus] = useState(false);
  const [musicID, setMusicID] = useState("deltaWave1");
  const [loading, setLoading] = useState(null);

  // useEffect(() => {
  //   let savedMusicID = localStorage.getItem("holda-music-data");

  //   if (savedMusicID) {
  //     if (musicData[savedMusicID]) {
  //       setMusicID(savedMusicID);
  //     } else {
  //       setMusicID(defaultMusicID);
  //     }
  //   } else {
  //     setMusicID(defaultMusicID);
  //   }
  // }, []);

  useEffect(() => {
    let itm = musicRef.current;
    console.log(itm, status);
    if (itm) {
      if (status) {
        itm.play();
        console.log("Playing");
      } else {
        itm.pause();
      }
    }
  }, [status, musicRef, musicID]);

  if (!musicID || loading) return null;

  return (
    <Container>
      <Audio ref={musicRef} loop>
        <source
          src={"/ambient-music" + musicData[musicID].src}
          type="audio/mpeg"
        />
      </Audio>

      {/* <SelectProject>
        <ProjectName>{musicData[musicID].name}</ProjectName>
      </SelectProject> */}

      <CustomButton
        style={{ borderRadius: "10px" }}
        height={"40px"}
        width={"40px"}
        onClick={playPause}
        hoverLabel={"Ambient Music"}
        icon={status ? <TbHeadphonesOff /> : <BsHeadphones />}
      ></CustomButton>
    </Container>
  );

  function openMusicSelector() {
    setForm({
      title: "Select Music",
      component: (
        <MusicSelector
          callback={(newID) => {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            setMusicID(newID);
          }}
        />
      ),
    });
  }

  function playPause() {
    setStatus(!status);
  }
}
