import { useContext, useEffect, useState } from "react";

import Context from "../Context";
import { styled } from "styled-components";
import { GrAdd, GrSelect } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import { nanoid } from "nanoid";
import {
  AiOutlineCheck,
  AiOutlineDown,
  AiOutlineEdit,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { serverLine } from "../controllers/serverLine";
import onChangeStop from "../controllers/onChangeStop";
import CustomSelect from "./CustomSelect";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

const NumberInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* background: var(--bgColor); */
  padding: 0 0;
  overflow: hidden;
  justify-content: space-between;
  gap: 2px;
  align-items: center;
  border-radius: 10px;
  heigth: 40px;
  /* width: 100%; */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const NumberInputButton = styled.div`
  display: flex;
  /* flex: 1; */
  height: 40px;
  text-align: center;
  width: 40px;
  background: var(--translucent);
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--translucentHard);
  }
`;

const NumberInputBox = styled.div`
  height: 40px;
  display: flex;
  flex: 1;
  padding: 0 15px;
  background: var(--translucent);
  justify-content: center;
  align-items: center;
`;

export default function IncrementDecrement({ value, onChange }) {
  return (
    <NumberInputContainer unselectable="on">
      <NumberInputButton
        onClick={() => {
          onChange(-1);
        }}
      >
        <AiOutlineLeft />
      </NumberInputButton>
      <NumberInputBox>{value}</NumberInputBox>
      <NumberInputButton
        onClick={() => {
          onChange(1);
        }}
      >
        <AiOutlineRight />
      </NumberInputButton>
    </NumberInputContainer>
  );
}
