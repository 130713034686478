import CryptoJS from "crypto-js";
import getIntermediateEncryptionKey from "./getIntermediateEncryptionKey";
import loadContent from "../../loadContent";

export default async function getEncryptedDocs(encryptionKey, type = "DIARY") {
  encryptionKey = getIntermediateEncryptionKey(encryptionKey);

  let diaryDocs = await loadContent({ type: type });

  let newDocs = {};

  for (let key in diaryDocs) {
    let diaryDoc = diaryDocs[key];
    diaryDoc.content = decryptContent(diaryDoc.content);

    if (type == "DIARY") {
      newDocs[diaryDoc.date] = diaryDoc;
    } else {
      newDocs[diaryDoc.docID] = diaryDoc;
    }
  }

  return newDocs;

  function decryptContent(data) {
    var bytes = CryptoJS.AES.decrypt(data, encryptionKey);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    data = JSON.parse(decryptedData);
    return data;
  }
}
