import CryptoJS from "crypto-js";

export default function getIntermediateEncryptionKey(encryptionKey) {
  var bytes = CryptoJS.AES.decrypt(
    window.loggedInUser.encryptedIntermediateKey,
    encryptionKey
  );
  let key = bytes.toString(CryptoJS.enc.Utf8);

  return key;
}
