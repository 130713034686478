import { useContext, useState } from "react";
import WithHeader from "./WithHeader";
import LoggedOutHomePage from "./pages/LoggedOutHomePage";
import Habits from "./tabs/Habits";
import Notes from "./tabs/Notes";
import Stat from "./tabs/Stat";
import Todo from "./tabs/Todo";
import Settings from "./Settings";
import Diary from "./tabs/diary/Diary";
import Secrets from "./tabs/Secrets";
import ChangeEncryptionKey from "./tabs/diary/ChangeEncryptionKey";
import Context from "../Context";
import LoadingSection from "./LoadingSection";
import TakeBreak from "./tabs/TakeBreak";

export default function Main({ tabType }) {
  const { loginStatus, loggedInUser } = useContext(Context);

  if (!loginStatus) return <LoggedOutHomePage />;

  if (!loggedInUser) return <LoadingSection />;

  let obj = {
    todo: <Todo />,
    notes: <Notes />,
    stat: <Stat />,
    habits: <Habits />,
    diary: <Diary />,
    settings: <Settings />,
    secrets: <Secrets />,
    changeEncryption: <ChangeEncryptionKey />,
    break: <TakeBreak />,
  };
  let child = obj[tabType];

  return <WithHeader>{child}</WithHeader>;
}
