import { serverLine } from "./serverLine";

function logout() {
  localStorage.removeItem("holda-user-token");
  localStorage.removeItem("savedEncryptionKey");

  window.location = "/";
}

export default logout;
