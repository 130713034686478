import styled from "styled-components";

import LogoSVG from "./LogoSVG";
import Link from "./Link";

const BrandContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  opacity: 0.8;
`;

const H1 = styled.h1`
  font-weight: 900;
  margin: 0;
  /* margin-top: -3px; */
  font-size: 28px;
  width: auto;
  width: 100%;
  font-weight: 100;

  @media (max-width: 900px) {
    font-size: 30px;
    /* display: none; */
  }
`;

export default function BrandContainer() {
  return (
    <Link href="/">
      <BrandContainerMain>
        <LogoSVG />
        <H1>Holda</H1>
      </BrandContainerMain>
    </Link>
  );
}
