import { useContext, useEffect, useState } from "react";

import Context from "../Context";
import { styled } from "styled-components";
import musicData from "../data/musicData";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;

const MusicContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  gap: 50px;
  padding: 10px 20px;
  border-radius: 15px;
  background: var(--translucent);
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--translucentHard);
  }
`;

export default function MusicSelector({ callback }) {
  const { setForm } = useContext(Context);

  return <Container>{renderMusics()}</Container>;

  function selectMusic(musicData) {
    setForm(null);
    callback(musicData);
    localStorage.setItem("holda-music-data", musicData);
  }

  function renderMusics() {
    let toReturn = [];

    for (let musicID in musicData) {
      let music = musicData[musicID];

      toReturn.push(
        <MusicBox
          value={musicID}
          name={music.name}
          key={musicID}
          selectMusic={selectMusic}
        />
      );
    }

    return toReturn;
  }
}

function MusicBox({ value, name, selectMusic }) {
  return (
    <MusicContainer
      onClick={() => {
        selectMusic(value);
      }}
    >
      {name}
    </MusicContainer>
  );
}
