import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../../Context";
import CryptoJS from "crypto-js";
import { useState } from "react";

import MaterialInput from "../../MaterialInput";
import CustomButton from "../../CustomButton";
import saveUserState from "../../../controllers/storage/saveUserState";
import { serverLine } from "../../../controllers/serverLine";
import LoadingSection from "../../LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 30px;
`;

const SubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-weight: 100;
`;

const ResetMessage = styled.div`
  padding: 25px;
  padding-bottom: 0;
  opacity: 0.5;
  color: tomato;
  /* width: 90%; */
`;

export default function EnterEncryptionKey({ setEncryptionKey }) {
  const { loggedInUser, setForm } = useContext(Context);
  const [newEncryptionKey, setNewEncryptionKey] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let savedKey = localStorage.getItem("savedEncryptionKey");
    if (savedKey) {
      if (encryptionKeyIsCorrect(savedKey)) {
        setEncryptionKey(savedKey);
      }
    }

    setLoading(false);
  }, []);

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <Titles>
        <Title>Enter Encryption Key</Title>
        <SubTitle>
          If you ever forget this password, the content can never be recovered.
        </SubTitle>
      </Titles>
      <MaterialInput
        label="Encryption Password"
        value={newEncryptionKey}
        onChange={updateKey}
      />
      <CustomButton onClick={checkEncryptionKey}>Enter</CustomButton>
      <CustomButton onClick={enterAndRemember}>Enter & Remember</CustomButton>
      <CustomButton
        variant="minimal-underlined"
        onClick={showResetAccountPrompt}
      >
        Forgot Password
      </CustomButton>
    </Container>
  );

  function updateKey(e) {
    setNewEncryptionKey(e.target.value);
  }

  function showResetAccountPrompt() {
    setForm(
      {
        component: (
          <ResetMessage>
            The diary is encrypted, all you can do is reset diary. Everything
            that is not encrypted, will not be affected
          </ResetMessage>
        ),
        title: "Reset Diary",

        buttons: [
          {
            name: "Reset Account",
            onClick: resetAccount,
          },
        ],
      },
      true
    );
  }

  function resetAccount() {
    saveUserState({
      encryptedIntermediateKey: null,
      encryptionKeyHashed: null,
    });

    serverLine.post("/wipe-diary-and-secrets");

    setForm(null);
  }

  function checkEncryptionKey() {
    if (encryptionKeyIsCorrect(newEncryptionKey)) {
      return setEncryptionKey(newEncryptionKey);
    } else {
      window.doAlert("Wrong Encryption Key");
    }
  }

  function enterAndRemember() {
    if (encryptionKeyIsCorrect(newEncryptionKey)) {
      localStorage.setItem("savedEncryptionKey", newEncryptionKey);
      window.doAlert(
        "Encryption key has been saved locally. It will be removed once you logout."
      );
      return setEncryptionKey(newEncryptionKey);
    } else {
      window.doAlert("Wrong Encryption Key");
    }
  }

  function encryptionKeyIsCorrect(valueToCheck) {
    let encryptionKeyHashed = CryptoJS.SHA256(valueToCheck);
    encryptionKeyHashed = encryptionKeyHashed.toString(CryptoJS.enc.Hex);

    if (encryptionKeyHashed == loggedInUser.encryptionKeyHashed) {
      return true;
    } else {
      return false;
    }
  }
}
