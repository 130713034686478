import getDateWithSlash from "../getDateWithSlash";
import getMonthName from "../getMonthName";

let weekDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export default function getXAxisForHozGraph({
  timeSpanType,
  startDate,
  dataPoints,
}) {
  let xAxis = [];
  var theDateObj = startDate;

  for (let i = 0; i < dataPoints; i++) {
    let theDate = getDateWithSlash(theDateObj);

    if (timeSpanType === "YEAR") {
      theDate = `${theDateObj.getMonth() + 1}/${theDateObj.getFullYear()}`;
    } else if (timeSpanType === "EVER") {
      theDate = theDateObj.getFullYear();
    }

    let label = null;

    if (timeSpanType === "WEEK") {
      label = weekDays[i] + " " + theDateObj.getDate();
    } else if (timeSpanType === "YEAR") {
      label = getMonthName(theDateObj);
    } else if (timeSpanType === "EVER") {
      label = theDateObj.getFullYear();
    } else {
      label = theDateObj.getDate();
    }

    xAxis.push({
      when: theDate,
      label: label,
    });

    if (timeSpanType === "YEAR") {
      theDateObj.setMonth(theDateObj.getMonth() + 1);
    } else if (timeSpanType === "EVER") {
      theDateObj.setFullYear(theDateObj.getFullYear() + 1);
    } else {
      theDateObj.setDate(theDateObj.getDate() + 1);
    }
  }

  return xAxis;
}
