import "./App.css";

import Boilerplate from "./components/Boilerplate";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./components/Main";
import SendAuthCode from "./components/sendAuthCode";

function App() {
  return (
    <BrowserRouter>
      <Boilerplate>
        <Routes>
          <Route path="/">
            <Route index element={<Main tabType={"todo"} />} />

            <Route path="settings" element={<Main tabType={"settings"} />} />

            <Route path="notes" element={<Main tabType={"notes"} />} />
            <Route path="stat" element={<Main tabType={"stat"} />} />
            <Route path="habits" element={<Main tabType={"habits"} />} />
            <Route path="diary" element={<Main tabType={"diary"} />} />
            {/* <Route path="local-notes" element={<OfflineNotes />} /> */}
            <Route path="todo" element={<Main tabType={"todo"} />} />
            <Route path="secrets" element={<Main tabType={"secrets"} />} />
            <Route path="break" element={<Main tabType={"break"} />} />
            <Route path="auth-redirect" element={<SendAuthCode />} />
            <Route
              path="change-encryption"
              element={<Main tabType={"changeEncryption"} />}
            />
            <Route path="*" element={"404"} />
          </Route>
        </Routes>
      </Boilerplate>
    </BrowserRouter>
  );
}

export default App;
