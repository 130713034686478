import { TextField } from "@mui/material";

export default function MaterialInput({
  value,
  onChange,
  label,
  disableUnderline,
  fontSize,
  multiline,
  maxRows,
  onEnter,
  rows,
  type,
  autoFocus = false,
  onlyYear,
  variant = "filled",
}) {
  return (
    <TextField
      autoFocus={autoFocus}
      type={type}
      InputLabelProps={{ style: { fontSize: fontSize } }}
      InputProps={{
        disableUnderline: disableUnderline ? true : false,

        style: {
          fontSize: fontSize,
          borderRadius: "10px",
          color: "#ffffff",
        },
      }}
      sx={{ input: { color: "#ffffff" } }}
      fullWidth
      multiline={multiline}
      maxRows={maxRows}
      onKeyDown={(ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === "Enter") {
          if (onEnter) {
            onEnter(ev);
          }
          // Do code here
          ev.preventDefault();
        }
      }}
      rows={rows}
      value={value}
      onChange={onChange}
      label={label}
      variant={variant}
    />
  );
}
