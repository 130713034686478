import getDateWithSlash from "../../getDateWithSlash";
import CryptoJS from "crypto-js";
import getIntermediateEncryptionKey from "./getIntermediateEncryptionKey";
import { serverLine } from "../../serverLine";

export default function saveEncryptedDoc({
  documentID,
  content,
  action,
  date,
  encryptionKey,
  type = "DIARY",
}) {
  if (!encryptionKey) return null;

  encryptionKey = getIntermediateEncryptionKey(encryptionKey);

  let encryptedContent = CryptoJS.AES.encrypt(
    JSON.stringify(content),
    encryptionKey
  ).toString();

  let newDoc = {
    docID: documentID,
    content: encryptedContent,
    date: date,
    action: action,
    type: type,
  };

  serverLine.post("/content", {
    ...newDoc,
    content: encryptedContent,
  });

  return newDoc;
}
