import styled from "styled-components";
import { useContext, useEffect, useState } from "react";

import Context from "../Context.js";
import LoadingSection from "./LoadingSection.js";
import { serverLine } from "../controllers/serverLine.js";
import { useSearchParams } from "react-router-dom";
import BrandContainer from "./BrandContainer.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* margin-top: 100px; */
  justify-content: center;
  height: 100vh;
  align-items: center;
  width: 100vw;
`;

const ErrorBox = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
`;

function SendAuthCode() {
  const [searchParams] = useSearchParams();
  let { dispatch } = useContext(Context);
  let [error, setError] = useState(false);

  useEffect(() => {
    console.log("req sending auth code");
    sendCode();
  }, []);

  if (error) return <ErrorBox>{error}</ErrorBox>;
  return (
    <Container>
      <BrandContainer />
      <LoadingSection />
    </Container>
  );

  function done(data) {
    localStorage.setItem("holda-user-token", data.token);
    console.log(data);
    window.location = "/";
  }

  function sendCode() {
    console.log("sending auth code");
    let location = window.location.origin + window.location.pathname;
    let code = searchParams.get("code");

    serverLine
      .post("/cookie", { code, location })
      .then(done)
      .catch(({ message }) => {
        if (typeof message == "object") message = JSON.stringify(message);
        setError(message);
      });
  }
}

export default SendAuthCode;
