import styled from "styled-components";

import Header from "./Header";
import { useContext } from "react";
import Context from "../Context";
import AlwaysVisibleNotes from "./tabs/AlwaysAvailableNotes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  padding-bottom: 50px;

  background-size: cover;
`;

const Decoration = styled.img`
  position: absolute;
  width: 100vw;
  object-fit: cover;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100vh;
  opacity: 0.5;

  ${({ colorMode }) => {
    if (colorMode == "DARK")
      return `
      
      opacity:1;

    `;
  }}
`;

const Main = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  gap: 50px;
  width: 62vw;
  justify-content: flex-start;

  align-items: center;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 700px) {
    width: 90vw;
  }
`;

export default function WithHeader({ children }) {
  const { colorMode } = useContext(Context);

  return (
    <Container>
      <Decoration colorMode={colorMode} src="/decoration.png" />
      <Header />
      <Main>{children}</Main>
    </Container>
  );
}
