export default function speak(newText) {
  let speech = new SpeechSynthesisUtterance();

  speech.lang = "en";
  speech.text = newText;

  let voices = window.speechSynthesis.getVoices();
  console.log(voices);

  if (!voices.length) {
    speechSynthesis.onvoiceschanged = () => {
      doTheSpeaking();
    };
  } else {
    doTheSpeaking();
  }

  function doTheSpeaking() {
    console.log("Voices Loaded");
    let voices = window.speechSynthesis.getVoices();
    var femaleVoice = voices.find((voice) => isEnglishAndFemale(voice.name));

    console.log(femaleVoice);
    speech.voice = femaleVoice;

    window.speechSynthesis.speak(speech);
  }
}

function isEnglishAndFemale(str) {
  str = str.toLowerCase();

  if (str.indexOf("english") !== -1 && str.indexOf("female") !== -1)
    return true;
  return false;
}
