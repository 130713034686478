import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext } from "react";
import Context from "../../Context";
import { FaGooglePlay } from "react-icons/fa";
import LogoSVG from "../LogoSVG";
import BrandContainer from "../BrandContainer";
import FeatureBox from "../FeatureBox";
import {
  RiBook2Line,
  RiClockwise2Line,
  RiMusic2Line,
  RiStickyNote2Line,
  RiTodoLine,
} from "react-icons/ri";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiNote, BiRightArrow } from "react-icons/bi";
import { FiBook } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  font-family: "Raleway", sans-serif;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  /* justify-content: space-between; */

  /* @media (max-width: 1200px) {
    flex-direction: column;
  } */

  @media (max-width: 700px) {
    background-image: unset;
    align-items: center;
    overflow-y: auto;
    flex-direction: column;
    /* padding: 25px; */
    /* align-items: flex-start; */
  }
`;

const TheImage = styled.img`
  width: 38vw;
  height: 100vh;
  object-fit: cover;

  @media (max-width: 700px) {
    /* width: 90vw; */
    display: none;
  }
`;

const Container = styled.div`
  width: 62vw;
  margin: 50px 0;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 70px 0;
  gap: 0;

  @media (max-width: 700px) {
    width: 100vw;
    margin: 0;
    /* align-items: center; */
    padding: 35px;
  }
`;

const Tagline = styled.div`
  font-weight: 100;
  line-height: 50px;
  font-size: 35px;

  /* background: linear-gradient(to bottom, var(--color), var(--color), #000); */
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-background-clip: text; */

  width: 50vw;
  @media (max-width: 700px) {
    width: 80vw;
    font-size: 25px;
    line-height: 40px;
  }
`;

const MainButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;

  align-items: center;
  border: none;

  width: 100%;

  @media (max-width: 700px) {
    width: auto;
    height: auto;
    justify-content: center;
    /* flex-direction: column; */
  }
`;

const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid;
  gap: 25px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 300px;
  }
`;

const BigButton = styled(Button)`
  width: auto;
  padding: 0 50px;
  flex: unset;
  border: none;
  height: 60px;
  box-shadow: 3px 5px 8px 0px #00000066;

  @media (max-width: 900px) {
    width: auto;
  }
`;

const DownloadAppButton = styled(Button)`
  @media (min-width: 950px) {
    display: none;
  }
`;

const LowOpacityText = styled.h1`
  opacity: 0.5;
  font-weight: 200;
  font-size: 21px;
  margin-bottom: -10px;
`;
const Medium = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  width: 40vw;

  @media (max-width: 900px) {
    width: auto;
    gap: 20px;
  }
`;

const Words = styled.div`
  margin-top: 0;
  display: flex;
  margin-top: 0;
  gap: 120px;

  flex-direction: column;

  @media (max-width: 700px) {
    gap: 50px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 35px;
`;

//A devlog/studylog/artlog Platform

const Icon = styled.img`
  height: 18px;
`;

const Tagline2 = styled.div`
  font-weight: 100;
  line-height: 50px;
  font-size: 20px;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

export default function LoggedOutHomePage() {
  let { setForm } = useContext(Context);

  let date = new Date();

  // return (
  //   <>
  //     {date.getDate()} / {date.getMonth() + 1} / {date.getFullYear()}
  //   </>
  // );

  return (
    <ParentContainer>
      <TheImage src="/cover10.jpeg" />
      <Container>
        <BrandContainer />

        <TextContainer>
          <Tagline>
            Fast <br />
            Minimal
            <br />
            All in one productivity tool
            <br />
            Holda, hold yourself responsible
          </Tagline>

          <LowOpacityText>Features</LowOpacityText>
          <Medium>
            <FeatureBox icon={<RiTodoLine />} text="Todos & Sub Todos" />
            <FeatureBox
              icon={<AiOutlineClockCircle />}
              text="Pomodoro Timer With Stat"
            />
            <FeatureBox icon={<BiNote />} text="Notes" />
            <FeatureBox icon={<FiBook />} text="Encrypted Diary" />
            <FeatureBox icon={<RiMusic2Line />} text="Ambient Music" />
          </Medium>
        </TextContainer>

        <MainButtons>
          <BigButton onClick={goToAuthScreen}>
            <GrGoogle />
            Login With Google
          </BigButton>

          <Button
            onClick={openMoreMenu}
            style={{
              width: "60px",
              height: "60px",
              fontSize: "21px",
              backgroundColor: "var(--bgColor)",
              color: "var(--color)",
              flex: "unset",
              opacity: 0.7,
            }}
          >
            <HiMenuAlt4 />
          </Button>
        </MainButtons>
      </Container>
    </ParentContainer>
  );

  function open(link) {
    return () => {
      return (window.location = link);
    };
  }

  function openMoreMenu() {
    setForm({
      options: [
        {
          name: "About us",
          onClick: open("https://arnav.upon.one"),
        },
      ],
    });
  }
}
