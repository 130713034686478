import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import LoadingSection from "../LoadingSection";

import HorizontalBarGraph from "../statLibrary/HorizontalBarGraph";
import toMinsOrHours from "../../controllers/toMinOrHours";

import WithHeader from "../WithHeader";
import { serverLine } from "../../controllers/serverLine";

const Container = styled.div`
  display: flex;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    flex-direction: row;
  }
`;

const Tab = styled.button`
  width: 10vw;
  padding: 15px;
  cursor: pointer;
  border-radius: 15px;
  color: var(--color);
  background: var(--translucent);
  border: none;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }

  ${({ highlight }) => {
    if (highlight)
      return `
      color:var(--bgColor);
      background:var(--color);
    
    `;
  }}

  @media (max-width: 700px) {
    width: auto;
  }
`;

const EmptyNote = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 50px 0;
  align-items: center;
  border-radius: 15px;
  background: var(--translucent);
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 15px;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Stat() {
  const [data, setData] = useState(null);

  useEffect(() => {
    serverLine.get("/stat").then(setData);
  }, []);

  console.log(data);

  if (data == null) return <LoadingSection />;

  return (
    <Container>
      <HorizontalBarGraph
        defaultInterval={{ value: "MONTH", label: "Month" }}
        dataProcessor={dataProcessor}
        title="Your Stat"
        rawData={data}
      />
    </Container>
  );

  function dataProcessor({
    timeSpan = { value: "WEEK" },
    xAxis,
    rawData,
    projectID_filter,
  }) {
    let processedData = [];
    let totalValue = 0;

    let newRawData = mergeDataPoints(timeSpan.value, rawData, projectID_filter);

    console.log(rawData, newRawData);

    for (let xAxisDataPoint of xAxis) {
      let theDate = xAxisDataPoint.when;

      let item = newRawData[theDate];
      let theValue = 0;

      if (item) {
        theValue = item.totalDurationInSecs;
        totalValue += theValue;
      }

      let bottomLabel = xAxisDataPoint.label;

      processedData.push({
        value: theValue,
        altInfo: toMinsOrHours({ unparsedSeconds: theValue }),
        bottomLabel: bottomLabel,
      });
    }

    let totalTime = toMinsOrHours({ unparsedSeconds: totalValue });

    return { data: processedData, totalTime };
  }

  function mergeDataPoints(timeSpanType, rawData, projectID_filter) {
    rawData = JSON.parse(JSON.stringify(rawData));

    if (projectID_filter) {
      for (let dateStr in rawData) {
        let dateStat = rawData[dateStr];
        let newDuration = 0;
        for (let sessionID in dateStat.sessionData) {
          let session = dateStat.sessionData[sessionID];
          if (session.projectID === projectID_filter) {
            newDuration += session.durationInSecs;
          }
        }

        dateStat.totalDurationInSecs = newDuration;
      }
    }

    if (timeSpanType === "WEEK" || timeSpanType === "MONTH" || !timeSpanType) {
      return rawData;
    } else if (timeSpanType === "YEAR") {
      let newRawData = {};

      for (let date in rawData) {
        let item = rawData[date];
        let dateObj = new Date(date);

        let monthAndYear = `${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;

        if (newRawData[monthAndYear]) {
          newRawData[monthAndYear].totalDurationInSecs +=
            item.totalDurationInSecs;
        } else {
          newRawData[monthAndYear] = {
            totalDurationInSecs: item.totalDurationInSecs,
          };
        }
      }

      return newRawData;
    } else if (timeSpanType === "EVER") {
      let newRawData = {};

      for (let date in rawData) {
        let item = rawData[date];
        let dateObj = new Date(date);

        let year = dateObj.getFullYear();

        if (newRawData[year]) {
          newRawData[year].totalDurationInSecs += item.totalDurationInSecs;
        } else {
          newRawData[year] = { totalDurationInSecs: item.totalDurationInSecs };
        }
      }

      return newRawData;
    }
  }
}
