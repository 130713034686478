import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../../Context";

import { useState } from "react";

import getDateWithSlash from "../../../controllers/getDateWithSlash";

import getEncryptedDocs from "../../../controllers/storage/encryptedDocs/getEncryptedDocs";
import CreateEncryptionKey from "./CreateEncryptionKey";
import EnterEncryptionKey from "./EnterEncryptionKey";
import DiaryDoc from "./DiaryDoc";
import CustomMasonry from "../../CustomMasonry";
import LoadingSection from "../../LoadingSection";
import { nanoid } from "nanoid";
import DiaryDocPreview from "./DiaryDocPreview";

const Container = styled.div`
  display: flex;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  width: 15vw;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
  }
`;

const Tab = styled.button`
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  color: var(--color);
  background: var(--translucent);
  border: none;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }

  ${({ highlight }) => {
    if (highlight)
      return `
      color:var(--bgColor);
      background:var(--color);
    
    `;
  }}

  @media (max-width: 700px) {
    width: auto;
  }
`;

const EmptyNote = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 50px 0;
  align-items: center;
  border-radius: 10px;
  background: var(--translucent);
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 15px;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function MainDiary({ encryptionKey }) {
  const [data, setData] = useState();
  const [todaysDocID, setTodaysDocID] = useState(null);

  let today = getDateWithSlash(new Date());

  useEffect(() => {
    getEncryptedDocs(encryptionKey).then((newData) => {
      console.log(newData);
      setData(newData);

      if (newData[today]) {
        setTodaysDocID(newData[today].docID);
      } else {
        setTodaysDocID(nanoid());
      }
    });
  }, []);

  if (!data || !todaysDocID) return <LoadingSection />;

  let todaysDocContent = null;

  if (data[today]) {
    todaysDocContent = data[today].content;
  } else {
  }

  let allDocs = [
    <DiaryDocPreview
      updateMainState={updateMainState}
      encryptionKey={encryptionKey}
      date={today}
      docID={todaysDocID}
      content={todaysDocContent}
    />,
  ];

  for (let key in data) {
    if (key !== today) {
      let theDoc = data[key];
      allDocs.push(
        <DiaryDocPreview
          updateMainState={updateMainState}
          encryptionKey={encryptionKey}
          docID={theDoc.docID}
          date={theDoc.date}
          content={theDoc.content}
        />
      );
    }
  }

  return (
    <Container>
      <CustomMasonry list={allDocs} />
    </Container>
  );

  function updateMainState({ newContent, date }) {
    let updatedData = { ...data };

    if (updatedData[date]) {
      updatedData[date].content = newContent;
    } else {
      updatedData[date] = { content: newContent, date, docID: todaysDocID };
    }

    setData(updatedData);
  }
}
