import { styled } from "styled-components";
import { CustomLexicalEditor } from "./CustomLexicalEditor";
import getImageURL from "../../controllers/getImageURL";
import MaterialInput from "../MaterialInput";

const Container = styled.div`
  display: flex;
  /* background: var(--translucent); */
  padding: 0;
  border-radius: 15px;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  flex: 1;
  font-size: 19px;

  background-color: var(--translucent);
  padding: 25px;
  /* padding-top: 0; */

  @media (max-width: 900px) {
    padding: 15px;
  }
`;

const Image = styled.img`
  width: 62vw;
  min-height: 200px;
  background-color: var(--translucent);
  height: auto;
  view-transition-name: figure-caption;
  border-radius: 15px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const TitleText = styled.input`
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  color: var(--color);
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 9px;
  margin-top: 0;

  @media (max-width: 900px) {
    font-size: 21px;
  }
`;

export default function RichTextEditor({
  value,
  onChange,
  docID,
  disableTitle,
  placeholder,
}) {
  if (!value) value = { title: "", content: null };

  // let titleComp = (
  //   <MaterialInput

  //     label="Title"
  //     variant="standard"
  //     fontSize="30px"
  //     multiline={true}
  //     value={value.title}
  //     onChange={onTitleChange}
  //   />
  // );

  let titleComp = (
    <TitleText
      placeholder="Title"
      value={value.title}
      onChange={onTitleChange}
    />
  );

  if (disableTitle) titleComp = null;

  return (
    <Container>
      {titleComp}
      <CustomLexicalEditor
        placeholder={placeholder}
        docID={docID}
        initialEditorState={value.content}
        onEditorChange={onEditorChange}
      />
    </Container>
  );

  function onTitleChange({ target }) {
    let newValue = { ...value };
    newValue.title = target.value;
    onChange(newValue);
  }

  function onEditorChange(newEditorState) {
    let newValue = { ...value };
    newValue.content = newEditorState;
    onChange(newValue);
  }
}
