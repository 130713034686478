import styled from "styled-components";

import saveEncryptedDoc from "../../../controllers/storage/encryptedDocs/saveEncryptedDoc";
import MaterialInput from "../../MaterialInput";
import RichTextEditorForDiary from "../../richTextEditor/RichTextEditorForDiary";
import getDateString from "../../../controllers/getDateString";
import saveLocalDocument from "../../../controllers/storage/document/saveLocalDocument";
import onChangeStop from "../../../controllers/onChangeStop";
import lexicalObjToString from "../../../controllers/lexicalObjToString";
import { useEffect, useState } from "react";

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  padding: 30px 20px;

  /* background-color: var(--translucent); */
`;

const TheDate = styled.div`
  font-size: 20px;
  font-weight: 900;
  margin-top: 0px;
  font-weight: 100;
  padding: 15px;
`;

const TheBox = styled.div`
  padding: 15px;
  background-color: var(--translucent);
  border-radius: 10px;

  ${({ label }) => {
    if (label)
      return `
    &:before {
      content: "${label}";
      opacity: 0.5;
    }
    
    `;
  }}
`;

let fieldVsPlaceholder = {
  title: "Describe your day in one line.",
  planning: "What's the plan?",
  accomplishments: "What did you accomplish today?",
  summary: "What Happened today?",
  learning: "What did you learn today?",
  mistakes: "What were you mistakes?",
};

export default function DiaryDoc({
  date,
  docID,
  content,
  encryptionKey,
  updateMainState,
}) {
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [contentState, setContentState] = useState(content);

  useEffect(() => {
    window.setTimeout(() => {
      setUpdateEnabled(true);
    }, 1000);
  }, []);

  if (!content) content = {};

  let options = [];

  for (let field in fieldVsPlaceholder) {
    let placeholder = fieldVsPlaceholder[field];
    options.push(getDiarySection(field, placeholder));
  }

  return <Container>{options}</Container>;

  function updateContent(field) {
    return (newValue) => {
      let newContent = { ...contentState };

      if (!updateEnabled) return;
      //Bug being prevented -> onChange fires on load which creates multiple docs with the same in the backend

      newContent[field] = newValue;
      setContentState(newContent);

      console.log(newContent);

      updateMainState({ docID, newContent, date });

      onChangeStop({
        callback: () => {
          saveEncryptedDoc({
            documentID: docID,
            date: date,
            content: newContent,
            encryptionKey,
          });
        },
        name: `${field}-${docID}`,
      });
    };
  }

  function getDiarySection(field, placeholder) {
    let theValue = content[field];
    let hasValue = false;

    let theText = lexicalObjToString(theValue);
    if (theText) {
      if (theText.trim()) {
        hasValue = true;
      }
    }

    return (
      <TheBox label={hasValue ? placeholder : null}>
        <RichTextEditorForDiary
          docID={docID}
          placeholder={placeholder}
          value={theValue}
          onChange={updateContent(field)}
        />
      </TheBox>
    );
  }
}
