import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../../Context";

import { useState } from "react";

import getDateWithSlash from "../../../controllers/getDateWithSlash";

import getMonthName from "../../../controllers/getMonthName";
import HabitAdder from "../../HabitAdder";
import { AiOutlineLeft } from "react-icons/ai";
import IncrementDecrement from "../../IncrementDecrement";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const LeftPart = styled.div`
  font-size: 21px;
`;
const BottomPart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
`;

let today = new Date();

let daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export default function HabitStatBox({
  habitID,
  habitData,
  habitStat,
  updateState,
}) {
  const [month, setMonth] = useState(
    new Date(`${today.getMonth() + 1}/1/${today.getFullYear()}`)
  );

  return (
    <Container>
      <TopPart>
        <LeftPart>{habitData.name}</LeftPart>
        <IncrementDecrement
          value={`${getMonthName(month)} ${month.getFullYear()}`}
          onChange={(newValue) => {
            console.log(month.toISOString());
            let newDate = new Date(month.toISOString());
            newDate.setMonth(month.getMonth() + newValue);
            console.log(newDate);
            setMonth(newDate);
          }}
        />
      </TopPart>
      <BottomPart>{renderDates()}</BottomPart>
    </Container>
  );

  function renderDates() {
    let allDateOfMonths = [];

    for (let i = 1; i <= daysInMonth[month.getMonth()]; i++) {
      let theDate = new Date(month.toISOString());
      theDate.setDate(i);
      theDate = getDateWithSlash(theDate);
      let dateStat = habitStat[theDate];
      let initialStatus = false;

      if (dateStat) {
        if (dateStat[habitID]) {
          initialStatus = true;
        }
      }

      allDateOfMonths.push(
        <DayOfMonth
          habitStat={habitStat}
          updateState={updateState}
          habitID={habitID}
          name={i}
          date={theDate}
          status={initialStatus}
        />
      );
    }

    return allDateOfMonths;
  }
}

const DayOfMonthContainer = styled.div`
  display: flex;
  /* padding: 10px; */
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--translucent);
  cursor: pointer;

  ${({ highlight, disabled }) => {
    if (disabled)
      return `

    opacity:0.5;
    pointer-events:none;
`;

    if (highlight)
      return `
    background: #8BC34A;
    color: #284507;
    font-weight: 700;
  `;

    return `
  

  &:hover {
    background: var(--translucentHard);
  }
  `;
  }}
`;

function DayOfMonth({ habitID, name, date, status, updateState }) {
  let today = new Date();
  let thisDate = new Date(date);

  return (
    <DayOfMonthContainer
      data1={thisDate.getTime()}
      data2={today.getTime()}
      disabled={thisDate.getTime() > today.getTime()}
      highlight={status}
      onClick={postStat}
    >
      {name}
    </DayOfMonthContainer>
  );

  function postStat() {
    let newValue = !status;

    updateState({
      date: date,
      habitID: habitID,
      value: newValue,
    });
  }
}
