import Link from "../Link";
import styled from "styled-components";
import CustomSelect from "../CustomSelect";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import getDateString from "../../controllers/getDateString";

const options = [
  { value: "WEEK", label: "7 Days" },
  { value: "MONTH", label: "30 Days" },
  { value: "YEAR", label: "12 month" },
  { value: "EVER", label: "7 Years" },
];

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  gap: 0;
  border: none;
  background: transparent;
  border-radius: 15px;
  /* padding: 0 15px; */
  align-items: center;
  overflow: hidden;

  background: var(--translucent);
`;

const Button = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  cursor: pointer;
  &:hover {
    background: var(--translucentHard);
  }
`;

const DateRange = styled.div`
  height: 100%;
  display: flex;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--translucentHard);
  border-right: 1px solid var(--translucentHard);
  padding: 0 10px;
`;

export default function GraphDurationPaginator({
  value,
  setValue,
  rangeLabel,
}) {
  if (!rangeLabel) return null;

  return (
    <Buttons>
      <Button
        onClick={() => {
          setValue(value + 1);
        }}
      >
        <AiOutlineLeft />
      </Button>
      <DateRange>{rangeLabel}</DateRange>
      <Button
        onClick={() => {
          if (value - 1 > -1) setValue(value - 1);
        }}
      >
        <AiOutlineRight />
      </Button>
    </Buttons>
  );

  // function getDateRange() {
  //   let firstDate = new Date(data[0].date);
  //   let lastDate = new Date(data[data.length - 1].date);

  //   return `${getDateString(firstDate)} - ${getDateString(lastDate)}`;
  // }
}
