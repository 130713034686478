import styled from "styled-components";

import saveEncryptedDoc from "../../../controllers/storage/encryptedDocs/saveEncryptedDoc";
import MaterialInput from "../../MaterialInput";
import RichTextEditorForDiary from "../../richTextEditor/RichTextEditorForDiary";
import getDateString from "../../../controllers/getDateString";
import saveLocalDocument from "../../../controllers/storage/document/saveLocalDocument";
import onChangeStop from "../../../controllers/onChangeStop";
import lexicalObjToString from "../../../controllers/lexicalObjToString";
import { useContext, useEffect, useState } from "react";
import DiaryDoc from "./DiaryDoc";
import Context from "../../../Context";

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  background-color: var(--translucent);
  cursor: pointer;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const TheDate = styled.div`
  font-size: 20px;
  font-weight: 900;
  margin-top: 0px;
  font-weight: 100;
`;

const TheBox = styled.div`
  padding: 15px;
  border-top: 1px solid var(--translucentHard);

  ${({ label }) => {
    if (label)
      return `
    &:before {
      content: "${label}";
      opacity: 0.5;
    }
    
    `;
  }}
`;

let fieldVsPlaceholder = {
  title: "Describe your day in one line.",
  planning: "What's the plan?",
  accomplishments: "What did you accomplish today?",
  summary: "What Happened today?",
  learning: "What did you learn today?",
  mistakes: "What were you mistakes?",
};

const Title = styled.div``;

export default function DiaryDocPreview(props) {
  const { setForm } = useContext(Context);
  let { date, content } = props;
  if (!content) content = {};

  let title = content.title;

  if (title) {
    title = lexicalObjToString(title);

    if (title.length > 30) {
      title = title.slice(0, 30) + "...";
    }
  }

  return (
    <Container onClick={open}>
      <TheDate>{getDateString(new Date(date), true)}</TheDate>
      <Title>{title ? title : "Click to write"}</Title>
    </Container>
  );

  function open() {
    setForm({
      title: getDateString(new Date(date), true),
      component: <DiaryDoc {...props} />,
    });
  }
}
