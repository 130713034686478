import { serverLine } from "../serverLine";

export default function saveUserState(changes, options) {
  let newLoggedInUserStat = window.loggedInUser;

  newLoggedInUserStat = { ...newLoggedInUserStat };

  for (let key in changes) {
    newLoggedInUserStat[key] = changes[key];
  }

  window.setLoggedInUser(newLoggedInUserStat);

  if (options) {
    if (options.onlyLocally) {
      return true;
    }
  }

  serverLine.patch("/profile", { changes });
}
