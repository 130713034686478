import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { VscListTree } from "react-icons/vsc";
import { useEffect, useState } from "react";

import { useContext } from "react";

import { nanoid } from "nanoid";
import loadContent from "../../controllers/loadContent";
import LoadingSection from "../LoadingSection";
import { RiSplitCellsVertical } from "react-icons/ri";
import Context from "../../Context";
import TodoEditorCore from "../TodoEditorCore";
import onChangeStop from "../../controllers/onChangeStop";
import { serverLine } from "../../controllers/serverLine";
import saveLocalDocument from "../../controllers/storage/document/saveLocalDocument";
import WithHeader from "../WithHeader";
import CustomButton from "../CustomButton";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  flex-direction: row;
  padding: 0;
  border-radius: 15px;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MainContainer = styled.div`
  display: flex;
  width: calc(62vw - 15vw);
  gap: 15px;
  flex-direction: column;
  padding: 20px 25px;
  border-radius: 10px;
  padding-bottom: 50px;
  background: var(--translucent);

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  color: var(--color);
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 9px;
  margin-top: 0;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  width: 15vw;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
  }
`;

const Tab = styled.button`
  background-color: var(--translucent);
  border: none;
  padding: 0;
  color: var(--color);
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  opacity: 0.7;
  text-transform: capitalize;
  transition: 0.15s ease-in-out;

  ${({ highlight }) => {
    if (highlight) {
      return `
        background-color:var(--translucentHard);
        opacity:1;

        `;
    }
  }}

  &:hover {
    background-color: var(--translucentHard);
    color: var(--color);
  }
`;

const TabCore = styled.div`
  padding: 15px 15px;
`;

const TabText = styled.div``;

const DeleteTabButton = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 0 15px;
  font-size: 15px;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const PlusIcon = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 0 15px;
  font-size: 15px;
`;

export default function TabButton({
  forAdding,
  createDoc,
  docID,
  selectedDocID,
  title,
  doDelete,
  selectTab,
}) {
  const { setForm } = useContext(Context);

  if (!title) title = "Untitled";
  if (title.length > 10) {
    title = title.slice(0, 10) + "...";
  }

  if (forAdding)
    return (
      <Tab onClick={createDoc}>
        <TabCore>
          <TabText> {title}</TabText>
        </TabCore>

        <PlusIcon>
          <AiOutlinePlus />
        </PlusIcon>
      </Tab>
    );

  return (
    <Tab
      key={docID}
      highlight={docID == selectedDocID ? 1 : 0}
      onClick={() => {
        if (docID == selectedDocID) {
          deleteCurrentTab();
        } else {
          selectTab(docID);
        }
      }}
    >
      <TabCore>
        <TabText>{title}</TabText>
      </TabCore>

      {docID == selectedDocID ? (
        <DeleteTabButton>
          <IoMdClose />
        </DeleteTabButton>
      ) : null}
    </Tab>
  );

  function deleteCurrentTab() {
    setForm(
      {
        title: "Are you sure you want to delete?",
        buttons: [
          {
            name: "Yes",
            onClick: doDelete,
          },
        ],
      },
      true
    );
  }
}
