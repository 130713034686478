import Link from "./Link";
import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import { serverLine } from "../controllers/serverLine";

import { useState } from "react";
import { FiBook, FiSettings, FiSun } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import {
  AiFillClockCircle,
  AiOutlineClockCircle,
  AiOutlineMenu,
  AiOutlineUser,
} from "react-icons/ai";
import { HiBell, HiOutlineBell, HiOutlineUser, HiUser } from "react-icons/hi";
import goToAuthScreen from "../controllers/goToAuthScreen";
import { GrGoogle } from "react-icons/gr";
import {
  RiHome2Fill,
  RiHome2Line,
  RiHome5Line,
  RiHomeFill,
  RiHomeLine,
  RiNotificationFill,
  RiNotificationLine,
  RiSearch2Line,
  RiSearchFill,
  RiSearchLine,
  RiSettings2Line,
  RiSettingsFill,
  RiSettingsLine,
  RiUserFill,
  RiUserLine,
} from "react-icons/ri";
import {
  MdMode,
  MdOutlineDarkMode,
  MdOutlineFreeBreakfast,
  MdSettings,
} from "react-icons/md";
import LogoSVG from "./LogoSVG";
import { BsMenuApp, BsSun } from "react-icons/bs";
import { TbSettings } from "react-icons/tb";
import BrandContainer from "./BrandContainer";
import PomodoroTimer from "./PomodoroTimer";
import AmbientMusicPlayer from "./AmbientMusicPlayer";
import CustomButton from "./CustomButton";
import { TfiStatsUp } from "react-icons/tfi";
import AlwaysVisibleNotes from "./tabs/AlwaysAvailableNotes";

const Container = styled.div`
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 62vw;
  align-items: start;
  margin-top: 50px;
  margin-bottom: 25px;
  gap: 50px;
  border-radius: 0;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 900px) {
    margin-bottom: 0px;
    width: 90vw;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  /* justify-content: space-between; */
  gap: 50px;
  padding: 0 0;
  border-radius: 0;
  border-radius: 0;
  width: 100%;
  /* padding: 10px 0; */
  padding-bottom: 13px;
  border-bottom: 1px solid var(--translucentHard);

  @media (max-width: 900px) {
    justify-content: space-between;
    gap: 0;
    height: auto;
    border: none;
    background: var(--translucent);
    padding: 0px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const LinkItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotifIndication = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: tomato;
  position: absolute;
  bottom: 3px;
  left: calc(50% - 4px);
`;

const LoginButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  color: var(--bgColor);
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 172px;
  border: 1px solid;
  padding: 10px 0;
  gap: 15px;
  background-color: var(--translucentHard);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--color);
  border: none;
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 200px;
  }
`;

const LinkButtonContainer = styled.div`
  display: flex;

  align-items: center;
  border-radius: 10px;
  font-size: 21px;
  cursor: pointer;
  /* background: var(--translucentHard); */

  /* width: calc(62vw / 7); */
  flex: 1;
  justify-content: center;
  height: 40px;
  position: relative;
  padding: 0 0;
  position: relative;

  position: relative;
  opacity: 0.7;

  ${({ isActive }) => {
    if (isActive)
      return `

      opacity:1;

      &:after {
        position: absolute;
        bottom: -15px;
        background: var(--color);
        height: 4px;
        border-radius: 5px;
        content:"";
        width: 100%;
        left: 0;
      }


    `;
  }}

  @media (max-width:900px) {
    font-size: 15px;
    padding: 10px 15px;

    ${({ isActive }) => {
      if (isActive)
        return `

      opacity:1;
      background: var(--translucent);

      &:after {
       display:none;
      }


    `;
    }}
  }
`;

const Button = styled.div`
  display: flex;
  height: 40px;
  /* width: 45px; */
  font-size: 15px;
  gap: 15px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  /* background: var(--translucentHard); */

  /* width: 100px; */
  justify-content: center;
  /* height: 50px; */
  padding: 0 15px;
  border: 1px solid var(--translucentHard);

  &:hover {
    background: var(--translucentHard);
    color: var(--color);
  }

  @media (max-width: 900px) {
    ${({ onlyDesktop }) => {
      if (onlyDesktop) return `display:none;`;
    }}
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-direction: row;

  @media (max-width: 900px) {
    /* flex-direction: column; */
    gap: 0;
    justify-content: space-between;
    /* align-items: flex-start; */
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 900px) {
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
  }
`;

const CustomLink = styled(Link)`
  display: flex;
  flex: 1;
`;

const AddAchievement = styled.button`
  position: fixed;
  bottom: 50px;
  right: 50px;

  background: var(--translucentHard);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(35px);
  border: none;
  color: color;
  padding: 15px 20px;
  font-size: 18px;
  /* opacity: 0.5; */
  box-shadow: 1px 1px 20px 4px rgb(0 0 0 / 37%);
  color: var(--color);
  z-index: 555555;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const OnlyDesktop = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const Bottom = styled.div``;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 90vw;
  margin-bottom: 50px;
  margin-top: 25px;
`;
const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Logo = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

export default function Header() {
  return (
    <Container>
      <Top>
        <Logo>
          <Link href="/">
            <BrandContainer />
          </Link>
        </Logo>

        <Buttons>
          <AmbientMusicPlayer />
          <PomodoroTimer />

          <CustomButton
            style={{ borderRadius: "10px" }}
            height={"40px"}
            width={"40px"}
            href="/break"
            hoverLabel="Take a break"
            icon={<MdOutlineFreeBreakfast />}
          ></CustomButton>

          <CustomButton
            style={{ borderRadius: "10px" }}
            height={"40px"}
            width={"40px"}
            hoverLabel="View Stat"
            icon={<TfiStatsUp />}
            href="/stat"
          />

          <CustomButton
            style={{ borderRadius: "10px" }}
            height={"40px"}
            width={"40px"}
            hoverLabel="Settings"
            icon={<AiOutlineMenu />}
            href="/settings"
          />
        </Buttons>
      </Top>

      <AlwaysVisibleNotes />
      {getLinks()}

      {/* </Top> */}
    </Container>
  );

  function getLinks() {
    let data = (
      <Links>
        <LinkButton name={"Todo"} link={"/"} />
        <LinkButton name={"Notes"} link={"/notes"} />
        {/* <LinkButton name={"Stat"} link={"/stat"} /> */}
        <LinkButton name={"Habits"} link={"/habits"} />
        <LinkButton name={"Diary"} link={"/diary"} />
        <LinkButton name={"Secrets"} link={"/secrets"} />
      </Links>
    );

    return data;
  }
}

function LinkButton({ link, name, nonActiveIcon, children }) {
  let pathName = window.location.pathname;

  // if(pathName == "")pathName = "/"
  let isActive = pathName === link;
  return (
    <Link href={link}>
      <LinkButtonContainer isActive={isActive}>{name}</LinkButtonContainer>
    </Link>
  );
}
