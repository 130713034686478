class Color {
  static fromARGB(a, r, g, b) {
    return `rgba(${r},${g},${b},${a / 255})`;
  }
}

let colors = [
  Color.fromARGB(255, 255, 107, 107),
  Color.fromARGB(255, 196, 232, 96),
  Color.fromARGB(255, 57, 193, 107),
  Color.fromARGB(255, 31, 207, 216),
  Color.fromARGB(255, 75, 64, 233),
  Color.fromARGB(255, 121, 57, 225),
  Color.fromARGB(255, 190, 37, 167),

  Color.fromARGB(255, 219, 27, 94),
  Color.fromARGB(255, 159, 43, 118),
];

export default function getStatColor(projectID, allProjects) {
  let theWorkTag = { 0: { name: "Work" } };
  allProjects = { ...theWorkTag, ...allProjects };

  const keys = Object.keys(allProjects);

  let index = keys.indexOf(projectID);

  if (index == -1) return colors[0];

  if (!colors[index]) return colors[0];

  return colors[index];
}
