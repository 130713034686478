import { styled } from "styled-components";

const Div = styled.div`
  cursor: pointer;
`;

export default function Link({ href, children, style }) {
  return (
    <Div style={style} onClick={goTo}>
      {children}
    </Div>
  );

  function goTo() {
    window.navigate(href);
  }
}
