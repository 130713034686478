import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import getDateWithSlash from "../../controllers/getDateWithSlash";
import { serverLine } from "../../controllers/serverLine";
import LoadingSection from "../LoadingSection";

import onChangeStop from "../../controllers/onChangeStop";
import { FiDelete } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import loadContent from "../../controllers/loadContent";
import saveLocalDocument from "../../controllers/storage/document/saveLocalDocument";
import { nanoid } from "nanoid";
import WithHeader from "../WithHeader";
import TabButton from "./TabButton";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import NotesCore from "./NotesCore";

const Container = styled.div`
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  width: 62vw;
  font-size: 15px;
  /* border-radius: 10px; */

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function AlwaysVisibleNotes() {
  const { loggedInUser } = useContext(Context);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.alwaysVisibleNotes) {
        setData(loggedInUser.alwaysVisibleNotes);
      }

      setLoading(false);
    }
  }, [loggedInUser]);

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <RichTextEditor
        placeholder="Type here to write always visible notes. It could be motivational text or reminders for yourself."
        disableTitle={true}
        docID={"alwaysVisibleNotes"}
        onChange={saveDoc}
        value={data}
      />
    </Container>
  );

  function saveDoc(theData) {
    setData(theData);
    serverLine.patch("/profile", { changes: { alwaysVisibleNotes: theData } });
  }
}
