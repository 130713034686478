import getDateWithSlash from "../../getDateWithSlash";
import { serverLine } from "../../serverLine";

export default function saveLocalDocument({
  documentID,
  content,
  type,
  action,
}) {
  serverLine.post("/content", {
    docID: documentID,
    content,
    type,
    action,
    date: getDateWithSlash(new Date()),
  });
}
