import styled from "styled-components";

import LineEditor from "./LineEditor";
import { nanoid } from "nanoid";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
`;

const Indent = styled.div`
  padding-left: 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export default function TodoEditorCore({ value, setValue }) {
  if (!value.length) value = [{ data: "", children: [], id: nanoid() }];

  let lastItem = value[value.length - 1];
  if (lastItem.data) {
    value.push({ data: "", children: [], id: nanoid() });
  }
  // return JSON.stringify(value);

  let toRender = renderTodos([], value);

  return toRender;

  function renderTodos(location, todoList) {
    if (!todoList) todoList = [];
    let children = [];
    for (let j = 0; j < todoList.length; j++) {
      let item = todoList[j];
      children.push(
        <LineEditor
          location={[...location, j]}
          key={item.id}
          value={value}
          onChange={onChange}
        />
      );

      let line = todoList[j];
      if (line.enableChildren) {
        let lastChild = line.children[line.children.length - 1];
        if (lastChild)
          if (lastChild.data) {
            line.children.push({ data: "", children: [], id: nanoid() });
          }

        if (!line.children.length)
          line.children.push({ data: "", children: [], id: nanoid() });

        children.push(renderTodos([...location, j], line.children));
      }
    }

    let toReturn = children;

    if (!location.length) return <Container>{toReturn}</Container>;

    return <Indent>{toReturn}</Indent>;
  }

  function onChange(newVal) {
    setValue(newVal);
  }
}
