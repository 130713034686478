import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../Context";

import { useState } from "react";

import getDateWithSlash from "../../controllers/getDateWithSlash";
import { serverLine } from "../../controllers/serverLine";
import LoadingSection from "../LoadingSection";

import onChangeStop from "../../controllers/onChangeStop";
import { FiDelete } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import loadContent from "../../controllers/loadContent";
import saveLocalDocument from "../../controllers/storage/document/saveLocalDocument";
import { nanoid } from "nanoid";
import WithHeader from "../WithHeader";
import TabButton from "./TabButton";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import NotesCore from "./NotesCore";

const Container = styled.div`
  display: flex;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  width: 15vw;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
  }
`;

const Tab = styled.button`
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  color: var(--color);
  background: var(--translucent);
  border: none;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }

  ${({ highlight }) => {
    if (highlight)
      return `
      color:var(--bgColor);
      background:var(--color);
    
    `;
  }}

  @media (max-width: 700px) {
    width: auto;
  }
`;

const EmptyNote = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 50px 0;
  align-items: center;
  border-radius: 10px;
  background: var(--translucent);
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 15px;
`;

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Notes() {
  const [data, setData] = useState(null);

  useEffect(() => {
    loadContent({ type: "NOTE" }).then(setData);
  }, []);

  if (!data) return <LoadingSection />;

  return (
    <NotesCore data={data} setData={setData} saveFunction={saveLocalDocument} />
  );
}
