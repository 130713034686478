export default function toMinsOrHours({ unparsedSeconds, unparsedMinutes }) {
  let min = 0;

  if (typeof unparsedMinutes !== "undefined") {
    min = parseInt(unparsedMinutes);
    min = Math.floor(min);
  } else {
    let sec = parseInt(unparsedSeconds);
    if (sec <= 60) return `${sec} secs`;
    min = sec / 60;
    min = Math.floor(min);
  }

  if (min > 60) {
    let hour = min / 60;

    hour = Math.round(hour * 10) / 10;
    // hour = Math.floor(hour);

    if (hour <= 24) {
      if (hour == 1) return hour + " Hr";
      return hour + " Hrs";
    } else {
      let days = hour / 24;

      days = Math.round(days * 10) / 10;

      if (days === 1) return days + " Day";
      return days + " Days";
    }
  }

  if (min == 1) return min + " Min";
  return min + " Mins";
}
