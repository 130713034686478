import { useContext, useEffect, useState } from "react";

import Context from "../Context";
import { styled } from "styled-components";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;

const DurationContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  gap: 50px;
  padding: 10px 20px;
  border-radius: 15px;
  background: var(--translucent);
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--translucentHard);
  }
`;

let options = [15, 20, 30, 45, 60, 120];

export default function DurationSelector({ callback }) {
  const { setForm } = useContext(Context);

  return <Container>{renderDurations()}</Container>;

  function selectDuration(durationData) {
    setForm(null);
    callback(durationData);
    localStorage.setItem("holda-duration-data", durationData);
  }

  function renderDurations() {
    let toReturn = [];

    for (let duaration of options) {
      toReturn.push(
        <DurationBox
          value={duaration}
          key={duaration}
          selectDuration={selectDuration}
        />
      );
    }

    return toReturn;
  }
}

function DurationBox({ value, selectDuration }) {
  return (
    <DurationContainer
      onClick={() => {
        selectDuration(value);
      }}
    >
      {value} Mins
    </DurationContainer>
  );
}
