import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import {
  AiOutlineDelete,
  AiOutlineDown,
  AiOutlineLeft,
  AiOutlinePlus,
  AiOutlineRight,
  AiOutlineUnorderedList,
  AiOutlineUp,
} from "react-icons/ai";
import { VscListTree } from "react-icons/vsc";
import { useContext, useEffect, useRef, useState } from "react";
import { MdDone } from "react-icons/md";
import { BiExpandAlt } from "react-icons/bi";
import Context from "../Context";
import { nanoid } from "nanoid";

const OldInput = styled.textarea`
  display: flex;

  background: transparent;
  border-radius: 0;
  max-width: 100%;
  padding: 0 15px;
  padding-left: 0;
  /* padding-top: 18px; */

  overflow: hidden;
  font-size: 17px;
  font-family: roboto;
  resize: none;
  /* min-height: 15px; */
  height: 100%;
  height: 23px;

  min-width: 150px;
  outline: none;
  /* background: var(--translucent); */
  border: none;
  /* border-bottom: 1px solid var(--translucentHard); */
  color: var(--color);

  ${({ value }) => {
    if (value) return `width: ${value.length * 10}px`;
  }}

  ${({ completed }) =>
    completed
      ? `
  
  text-decoration: line-through;
  pointer-events:none;
  opacity:0.5;
  
  `
      : ""};
`;

const Input = styled.input`
  display: flex;

  background: transparent;
  border-radius: 0;
  max-width: 100%;
  padding: 0 15px;
  padding-left: 0;
  /* padding-top: 18px; */

  overflow: hidden;
  font-size: 17px;
  font-family: roboto;
  resize: none;
  /* min-height: 15px; */
  height: 100%;
  height: 23px;

  min-width: 150px;
  outline: none;
  /* background: var(--translucent); */
  border: none;
  /* border-bottom: 1px solid var(--translucentHard); */
  color: var(--color);

  ${({ value }) => {
    if (value) return `width: ${value.length * 10}px`;
  }}

  ${({ completed }) =>
    completed
      ? `
  
  text-decoration: line-through;
  pointer-events:none;
  opacity:0.5;
  
  `
      : ""};

  &::placeholder {
    color: #999;
  }
`;

const LineContainer = styled.div`
  display: flex;
  width: 99%;
  gap: 0px;
  position: relative;
  border-radius: 0;
  /* overflow: hidden; */
  flex-direction: row;
  justify-content: flex-start;

  /* &:before {
    content: "・";
    margin-right: 6px;
    font-weight: 900;
    line-height: 1.1;
    height: 23px;
    font-size: 18px;
    transform: scale(1.5);
  } */

  ${({ faded }) => {
    if (faded)
      return `
      animation-name: lineContainerFadeAway;
      animation-duration: 0.25s;

    `;
  }}
`;

const InputParent = styled.div`
  position: relative;
  height: 100%;
  max-width: 80%;
`;

const Completed = styled.div`
  ${({ completed, level }) => {
    if (completed)
      return `
      
    position: absolute;
    left: ${level == 2 ? "0" : "0%"};
    top: 0;
    width: 100%;
    pointer-events: none;
    border-radius: 5px;
    height: 100%;
    background: var(--bgColor2);
    animation-name: strikeAnimation;
    animation-duration: 0.3s;
      
    background-color: #e5e5f7;
    background-size: 10px 10px;   
    background-image: repeating-linear-gradient(45deg, var(--bgColor) 0, var(--bgColor) 1px, var(--bgColor2) 0, var(--bgColor2) 50%);

    `;
  }}
`;

const Button = styled.div`
  height: 23px;
  width: 35px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  opacity: 0.4;
  border-radius: 50px !important;
  /* background: var(--translucent); */

  /* background: var(--translucent); */
  border: none;
  /* border: 1px solid var(--translucentHard); */

  &:hover {
    background-color: var(--color);
    color: var(--bgColor);
  }
`;

const CheckMarkButton = styled.div`
  height: 23px;
  width: 23px;
  margin-top: 0;
  margin-right: 10px;

  /* background-color: var(--translucentHard); */
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  opacity: 0.6;
  border-radius: 50px !important;
  /* background: var(--translucent); */

  /* background: var(--translucent); */
  border: none;
  /* border: 1px solid var(--translucentHard); */
  border: 1px solid var(--color);
  /* transition: 0.25s ease-in; */
  &:hover {
    background-color: var(--color);
    color: var(--bgColor);
    font-size: 15px;
  }
`;

const Buttons = styled.div`
  height: 23px;
  display: flex;

  margin-left: 10px;
  flex-direction: row;
  /* padding: 0 10px; */
  justify-content: center;
  align-items: center;

  overflow: hidden;
  /* border: 1px solid var(--translucentHard); */
  border-radius: 50px;
  /* opacity: 0.8; */
  gap: 7px;
`;

export default function LineEditor({ value, onChange, location }) {
  const { popupAlert } = useContext(Context);
  const [lineVal, setLineVal] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    let lineIdx = getLineIndex();
    let theLine = getTheLine();
    console.log(
      "ATTACHED",
      theLine.line.isNew,
      !theLine.line.data,
      theLine.line.data,
      lineIdx
    );
  }, []);

  useEffect(() => {
    setTextareaSize();

    if (inputRef.current) {
      let lineIdx = getLineIndex();
      let theLine = getTheLine();
      console.log("FOCUSING", theLine.line.isNew, !theLine.line.data, lineIdx);

      if (theLine.line.isNew && !theLine.line.data) {
        console.log("FOCUSING", theLine.line.isNew, lineIdx);
        inputRef.current.focus();
        delete theLine.line.isNew;
        onChange(theLine.state);
      }
    }
  }, [inputRef]);

  useEffect(() => {
    let diggedValue = "";
    let theLine = getTheLine();
    diggedValue = theLine.line.data;
    setLineVal(diggedValue);
  }, [value]);

  let childrenIsEnabled = hasChildrenEnabled();

  let addLevelButton = (
    <Button key="addLevel" onClick={enableChildren}>
      {childrenIsEnabled ? <AiOutlineLeft /> : <AiOutlineRight />}
    </Button>
  );

  // if (hasChildrenEnabled()) {
  //   addLevelButton = null;
  // }

  let deleteLineButton = (
    <CheckMarkButton key="delete" onClick={fadeIt}>
      <MdDone />
    </CheckMarkButton>
  );

  let buttons = [addLevelButton];

  let theLine = getTheLine();

  return (
    <LineContainer faded={isFaded()}>
      {deleteLineButton}
      <InputParent>
        <Completed level={location.length} />

        <Input
          data={JSON.stringify(theLine.line)}
          ref={inputRef}
          role="presentation"
          type="text"
          autoComplete="off"
          onKeyDown={onKeyDown}
          name={"Bullet Point"}
          placeholder={"Type bullet point here"}
          onChange={lineUpdated}
          value={lineVal}
        />
      </InputParent>

      <Buttons>{buttons}</Buttons>
    </LineContainer>
  );

  function onKeyDown(e) {
    console.log(e.target.value);
    console.log(e.keyCode);
    if (e.keyCode == 13) {
      e.preventDefault();

      setTimeout(() => {
        let par = getTheParent();
        let lineIdx = getLineIndex();

        par.line.children.splice(lineIdx + 1, 0, {
          data: "",
          children: [],
          id: nanoid(),
          isNew: true,
        });

        console.log(par.line);
        onChange(par.state);
      }, 100);
      return false;
    } else if (e.keyCode === 9) {
      e.preventDefault();
      enableChildren();
    }
  }

  function setTextareaSize() {
    if (inputRef) {
      if (inputRef.current) {
        let ele = inputRef.current;

        // console.log("re", ele.scrollHeight);
        ele.style.height = ele.scrollHeight + "px";
      }
    }
  }

  function getTheLine() {
    let newValue = [...value];
    let theLine = { children: newValue };
    for (let item of location) {
      theLine = theLine.children[item];
    }
    return { line: theLine, state: newValue };
  }

  function getLineIndex() {
    let newLoc = [...location];

    newLoc = newLoc.reverse();

    return newLoc[0];
  }

  function getTheParent() {
    let newValue = [...value];
    let theLine = { children: newValue };

    let parentLoc = [...location];
    parentLoc.pop();
    for (let item of parentLoc) {
      theLine = theLine.children[item];
    }
    return { line: theLine, state: newValue };
  }

  function lineUpdated(e) {
    let target = e.target;

    setTextareaSize();

    setLineVal(target.value);

    let lineData = getTheLine();
    lineData.line.data = target.value;

    onChange(lineData.state);

    return false;
  }

  function fadeIt() {
    let lineData = getTheLine();

    lineData.line.faded = true;

    onChange(lineData.state);

    setTimeout(() => {
      deleteLine();
    }, 250);
  }

  function isFaded() {
    let newValue = [...value];
    let pos = { children: newValue };
    for (let loc of location) {
      if (pos.faded) return true;
      pos = pos.children[loc];
    }
    return pos.faded;
  }

  function deleteLine() {
    let lineIndex = getLineIndex();
    let parent = getTheParent();

    parent.line.children.splice(lineIndex, 1);

    onChange(parent.state);
  }

  function hasChildrenEnabled() {
    let lineData = getTheLine();

    return lineData.line.enableChildren;
  }

  function enableChildren() {
    let lineData = getTheLine();

    if (lineData.line.enableChildren == true) {
      lineData.line.enableChildren = false;
      onChange(lineData.state);
      return;
    }

    // if (location.length > 3) {
    //   popupAlert("Upgrade to premium to add more levels");
    //   return;
    // }

    lineData.line.enableChildren = true;
    if (!lineData.line.children) lineData.line.children = [];
    lineData.line.children.push({
      data: "",
      id: nanoid(),
      children: [],
      isNew: true,
    });
    onChange(lineData.state);
  }
}
