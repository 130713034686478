import { styled } from "styled-components";
import { CustomLexicalEditor } from "./CustomLexicalEditor";
import getImageURL from "../../controllers/getImageURL";
import MaterialInput from "../MaterialInput";

const Container = styled.div`
  display: flex;
  /* background: var(--translucent); */
  padding: 0;
  border-radius: 15px;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  flex: 1;
  font-size: 18px;

  /* background-color: var(--translucent); */
  padding: 0;
`;

const Image = styled.img`
  width: 62vw;
  min-height: 200px;
  background-color: var(--translucent);
  height: auto;
  view-transition-name: figure-caption;
  border-radius: 15px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const TitleText = styled.div`
  font-weight: 800;
  font-size: 50px;
`;

export default function RichTextEditorForDiary({
  value,
  onChange,
  docID,
  placeholder,
}) {
  return (
    <Container>
      <CustomLexicalEditor
        placeholder={placeholder}
        initialEditorState={value}
        onEditorChange={onChange}
        docID={docID}
      />
    </Container>
  );
}
