import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { VscListTree } from "react-icons/vsc";
import { useEffect, useState } from "react";

import { useContext } from "react";

import { nanoid } from "nanoid";
import loadContent from "../../controllers/loadContent";
import LoadingSection from "../LoadingSection";
import { RiSplitCellsVertical } from "react-icons/ri";
import Context from "../../Context";
import TodoEditorCore from "../TodoEditorCore";
import onChangeStop from "../../controllers/onChangeStop";
import { serverLine } from "../../controllers/serverLine";
import saveLocalDocument from "../../controllers/storage/document/saveLocalDocument";
import WithHeader from "../WithHeader";
import CustomButton from "../CustomButton";
import TabButton from "./TabButton";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  flex-direction: row;
  padding: 0;
  border-radius: 15px;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MainContainer = styled.div`
  display: flex;
  width: calc(62vw - 15vw);
  gap: 15px;
  flex-direction: column;
  padding: 20px 25px;
  border-radius: 10px;
  padding-bottom: 50px;
  background: var(--translucent);

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
  color: var(--color);
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 9px;
  margin-top: 0;

  @media (max-width: 900px) {
    font-size: 21px;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  width: 15vw;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
  }
`;

const Tab = styled.button`
  background-color: var(--translucent);
  border: none;
  padding: 0;
  color: var(--color);
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  opacity: 0.7;
  text-transform: capitalize;
  transition: 0.15s ease-in-out;

  ${({ highlight }) => {
    if (highlight) {
      return `
        background-color:var(--translucent);
        opacity:1;

        `;
    }
  }}

  &:hover {
    background-color: var(--translucentHard);
    color: var(--color);
  }
`;

const TabCore = styled.div`
  padding: 15px 15px;
`;

const TabText = styled.div``;

const DeleteTabButton = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 0 15px;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const PlusIcon = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 0 15px;
`;

export default function Todo() {
  const { setForm } = useContext(Context);
  const [selectedDocID, setSelectedDocID] = useState(null);
  let [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTodos();
  }, []);

  async function loadTodos() {
    let theData = await loadContent({ type: "TODO" });

    setDocuments(theData);

    let vals = Object.keys(theData);

    if (vals) {
      if (vals[0]) {
        setSelectedDocID(vals[0]);
      }
    }
  }

  if (documents == null || loading) return <LoadingSection />;

  if (!documents[selectedDocID] || !selectedDocID)
    return <Container>{renderTabs()} </Container>;

  return (
    <Container>
      {renderTabs()}
      <MainContainer>
        <Input
          placeholder="Type Title Here"
          onChange={titleChange}
          value={documents[selectedDocID].content.title}
        ></Input>
        <TodoEditorCore
          value={documents[selectedDocID].content.todos}
          setValue={onContentChange}
        />
      </MainContainer>
    </Container>
  );

  function titleChange(e) {
    let newDocs = { ...documents };
    newDocs[selectedDocID].content.title = e.target.value;
    setDocuments(newDocs);

    onChangeStop({
      callback: () => {
        saveLocalDocument({
          type: "TODO",
          documentID: selectedDocID,
          content: newDocs[selectedDocID].content,
        });
      },
      name: "todosTitle",
    });
  }

  function onContentChange(newVal) {
    let newDocs = { ...documents };
    newDocs[selectedDocID].content.todos = newVal;
    setDocuments(newDocs);

    onChangeStop({
      callback: () => {
        saveLocalDocument({
          type: "TODO",
          documentID: selectedDocID,
          content: newDocs[selectedDocID].content,
        });
      },
      name: "todoContentChange",
    });
  }

  function renderTabs() {
    let tabs = [];

    for (let docID in documents) {
      let title = "Untitled";

      if (documents[docID].content) {
        if (documents[docID].content.title) {
          title = documents[docID].content.title;
          title = title.slice(0, 15);
        }
      }

      tabs.push(
        <TabButton
          docID={docID}
          selectTab={selectTab}
          selectedDocID={selectedDocID}
          title={title}
          doDelete={doDelete}
        />
      );
    }

    return (
      <Tabs>
        <TabButton createDoc={createDoc} forAdding={true} title="New Todo" />
        {tabs}
      </Tabs>
    );
  }

  function doDelete() {
    let newDocs = { ...documents };

    let oldDocID = selectedDocID;
    delete newDocs[selectedDocID];

    let ids = Object.keys(newDocs);

    console.log(ids);
    setSelectedDocID(null);
    if (ids) {
      if (ids.length) {
        setSelectedDocID(ids[0]);
      }
    }

    setDocuments(JSON.parse(JSON.stringify(newDocs)));

    saveLocalDocument({
      action: "DELETE",
      documentID: oldDocID,
      type: "TODO",
    });
    setForm(null, true);
  }

  function selectTab(newVal) {
    setSelectedDocID(newVal);
  }

  async function createDoc() {
    setLoading(true);

    let newDocID = nanoid();
    saveLocalDocument({
      type: "TODO",
      documentID: newDocID,
      content: { title: "", todos: [] },
    });

    let newDocuments = { ...documents };
    newDocuments[newDocID] = {
      docID: newDocID,
      content: { title: "", todos: [] },
    };

    setDocuments(newDocuments);
    setSelectedDocID(newDocID);
    // setData(newData);

    setLoading(false);
  }
}
