import { useContext, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../../../Context";
import CryptoJS from "crypto-js";
import { useState } from "react";

import { nanoid } from "nanoid";
import WithHeader from "../../WithHeader";
import TabButton from "../TabButton";
import MaterialInput from "../../MaterialInput";
import CustomButton from "../../CustomButton";
import saveUserState from "../../../controllers/storage/saveUserState";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 30px;
`;

const SubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-weight: 100;
`;
export default function CreateEncryptionKey({ setEncryptionKey }) {
  const [newEncryptionKey, setNewEncryptionKey] = useState();
  const [confirmKey, setConfirmKey] = useState();

  return (
    <Container>
      <Titles>
        <Title>Create Encryption Key</Title>
        <SubTitle>
          If you ever forget this password, the content can never be recovered.
        </SubTitle>
      </Titles>

      <MaterialInput
        label="Encryption Password"
        value={newEncryptionKey}
        onChange={updateKey}
      />
      <MaterialInput
        label="Confirm Encryption Password"
        value={confirmKey}
        onChange={updateConfirmKey}
      />
      <CustomButton onClick={createEncryptionKey}>Create Key</CustomButton>
    </Container>
  );

  function updateKey(e) {
    setNewEncryptionKey(e.target.value);
  }

  function updateConfirmKey(e) {
    setConfirmKey(e.target.value);
  }

  function createEncryptionKey() {
    if (newEncryptionKey !== confirmKey) {
      return window.doAlert("Make sure the same password has been typed");
    }

    localStorage.setItem("holda-saved-diary", "");
    let intermediateKey = nanoid();
    let encryptedIntermediateKey = CryptoJS.AES.encrypt(
      intermediateKey,
      newEncryptionKey
    ).toString();

    let encryptionKeyHashed = CryptoJS.SHA256(newEncryptionKey);

    encryptionKeyHashed = encryptionKeyHashed.toString(CryptoJS.enc.Hex);

    saveUserState({ encryptionKeyHashed, encryptedIntermediateKey });

    setEncryptionKey(newEncryptionKey);
  }
}
